import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance, apiInstanceFetch } from "../../Component/Api/axiosApi";
import { setToast } from "../../Component/Api/toastServices";

const initialState = {
  userData: [],
  particularUserData: {},
  isLoading: false,
  isSkeleton: false,
  total: null,
};

export const getAllUserData = createAsyncThunk(
  "admin/user/getUsers",
  async (payload) => {
    return apiInstance.get(
      `admin/user/getUsers?start=${payload?.start}&limit=${payload?.limit}`
    );
  }
);

export const getParticularUser = createAsyncThunk(
  "admin/user/getProfileOfUser",
  async (payload) => {
    return apiInstance.get(`admin/user/getProfileOfUser?userId=${payload}`);
  }
);

export const userSwitch = createAsyncThunk(
  `admin/user/isBlockOrNotUser`,
  async (payload) => {
    return apiInstanceFetch.patch(`admin/user/isBlockOrNotUser?userId=${payload}`);
  }
);

const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllUserData.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getAllUserData.fulfilled, (state, action) => {
      state.isSkeleton = false;
      state.userData = action?.payload?.user;
      state.total = action?.payload?.totalUser;
    });

    builder.addCase(getAllUserData.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(getParticularUser.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getParticularUser.fulfilled, (state, action) => {
      state.isSkeleton = false;
      state.particularUserData = action?.payload?.user;
      state.total = action?.payload?.totalUser;
    });

    builder.addCase(getParticularUser.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(userSwitch.pending, (state, action) => {
      state.isLoading = true;
    });
       builder.addCase(userSwitch.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        state.userData = state.userData?.map((item) => {
          if (item?._id === action.meta.arg) {
            return action?.payload?.user;
          }
          return item;
        });
        setToast(
          "success",
          action?.payload?.user?.isBlock === false ? "User unblock succesfully" : "User block succesfully"     
        );
      } else {
        setToast("error", action.payload.message);
      }
    });
    builder.addCase(userSwitch.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default userSlice.reducer;
