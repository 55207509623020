import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import logo from "../../assets/images/logo.png";
import { login, signUp } from "../../store/slice/authSlice";
import Input from "../extra/Input";
import LoginLeft from "../../assets/images/loginPage.png";
import Button from "../extra/Button";
import { projectName } from "../utils/config";

function Registration(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [purchaseCode,setPurchaseCode] = useState("")
  const [error, setError] = useState({
    login: "",
    password: "",
    newPassword: "",
    purchaseCode:""
  });

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    if (!email || !password || !newPassword || password !== newPassword || !purchaseCode) {
      let error = {};
      if (!email) error.email = "Enter ID Is Required !";
      if (!password) error.password = "Password is required !";
      if (!newPassword) error.newPassword = "Confirm password is required !";
      if (password !== newPassword)
        error.newPassword = "New Password and Confirm Password doesn't match !";
      if(!purchaseCode) error.purchaseCode  = "Purchase code is required ! "
      return setError({ ...error });
    } else {
      let login = {
        email: email,
        password: password,
        code:purchaseCode
      };
      dispatch(signUp(login));
    }
  };



  return (
    <div className="login-page">
      <form className="background">
        <div className="login-form">
          <div className="login-body">
            <div className="login-right">
              <div className="login-title">
                <span>{projectName}</span>
              </div>
              <div className="login-img">
                <img src={LoginLeft} />
              </div>
            </div>
            <div className="login-content">
              <div className="login-mobile-img">
                <div className="login-title">
                  <img src={logo} />
                </div>
              </div>
              <h4>
                Welcome Back,<span>Sign Up</span>
              </h4>
              <h6>Please Sign Up With Email ,Password and Purchase Code</h6>
              <Input
                label={`Enter Email`}
                id={`email`}
                type={`text`}
                value={email}
                errorMessage={error.email && error.email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (!e.target.value) {
                    return setError({
                      ...error,
                      email: `Enter ID Is Required`,
                    });
                  } else {
                    return setError({
                      ...error,
                      email: "",
                    });
                  }
                }}
              />
              <div className="mt-3 w-100">
                <Input
                  label={`Enter Password`}
                  id={`enterPassword`}
                  type={`password`}
                  value={password}
                  errorMessage={error.password && error.password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        password: `Enter Password Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        password: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="mt-3 w-100">
                <Input
                  label={`Enter Confirm Password`}
                  id={`enterPassword`}
                  type={`password`}
                  value={newPassword}
                  errorMessage={error.newPassword && error.newPassword}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        newPassword: `Confirm Password Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        newPassword: "",
                      });
                    }
                  }}
                />
              </div>
              <Input
                label={`Enter Purchase code`}
                id={`purchaseCode`}
                type={`text`}
                value={purchaseCode}
                errorMessage={error.purchaseCode && error.purchaseCode}
                onChange={(e) => {
                  setPurchaseCode(e.target.value);
                  if (!e.target.value) {
                    return setError({
                      ...error,
                      purchaseCode: `Purchase code Is Required`,
                    });
                  } else {
                    return setError({
                      ...error,
                      purchaseCode: "",
                    });
                  }
                }}
              />
              <div className="d-flex justify-content-between">
                <Button
                  btnName={"Sign Up"}
                  type={"button"}
                  onClick={handleSubmit}
                  newClass={"sign-btn"}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default connect(null, { login })(Registration);
