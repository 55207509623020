import React, { useState } from "react";
import { connect } from "react-redux";
import { Box, Tab, Tabs } from "@mui/material";
import { ReactComponent as PendingComplainImg } from "../../../assets/images/icon/pendingComplain.svg";
import { ReactComponent as SolvedComplainImg } from "../../../assets/images/icon/solvedcomplain.svg";
import Title from "../../extra/Title";
import PendingComplain from "./PendingComplain";
import SolvedComplain from "./SolvedComplain";

const ComplainMain = (props) => {
  const getValue = localStorage.getItem("complain");
  const [value, setValue] = useState(Number(getValue) ? Number(getValue) : 0);

  const renderEditComponent = React.useCallback(() => {
    switch (value) {
      case 0:
        return <PendingComplain type="Pending" />;
      case 1:
        return <SolvedComplain type="Solved" />;
      default:
        break;
    }
  }, [value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem("complain", newValue);
  };

  return (
    <div className="giftPage">
      <Title title={"Complain"} />
      <div className="row">
        <div className="col-2 gift-tab mt-2">
          <Box>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="icon tabs example"
            >
              <Tab
                icon={<PendingComplainImg />}
                iconPosition="start"
                label="Pending"
              />
              <Tab icon={<SolvedComplainImg />} iconPosition="start" label="Solved" />
            </Tabs>
          </Box>
        </div>
      </div>
      {renderEditComponent()}
    </div>
  );
};
export default connect(null, {})(ComplainMain);
