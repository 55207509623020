import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import logo from "../../assets/images/logo.png";
import { login } from "../../store/slice/authSlice";
import Input from "../extra/Input";
import LoginLeft from "../../assets/images/loginPage.png";
import Button from "../extra/Button";
import { projectName } from "../utils/config";

function Login(props) {
  const dispatch = useDispatch();
  const [enterID, setEnterId] = useState("demo@admin.com");
  const [password, setPassword] = useState("123456");
  const [error, setError] = useState({
    login: "",
    password: "",
  });

  const handleSubmit = (e) => {
    if (!enterID || !password) {
      let error = {};
      if (!enterID) error.enterID = "Enter ID Is Required !";
      if (!password) error.password = "Password is required !";
      return setError({ ...error });
    } else {
      let login = {
        email: enterID,
        password: password,
      };
      props.login(login);
    }
  };

  const handleDemoLogin = () => {
    const details = {
      email: "demo@admin.com",
      password: "123456",
    };

    props.login(details);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit();
    }
  };

  return (
    <div className="login-page">
      <form className="background">
        <div className="login-form">
          <div className="login-body">
            <div className="login-right">
              <div className="login-title">
                <span>{projectName}</span>
              </div>
              <div className="login-img">
                <img src={LoginLeft} />
              </div>
            </div>
            <div className="login-content">
              <div className="login-mobile-img">
                <div className="login-title">
                  <img src={logo} />
                </div>
              </div>
              <h4>
                Welcome Back,<span>Sign In</span>
              </h4>
              <h6>Please Sign In With Email Or Password</h6>
              <Input
                label={`Enter Email`}
                id={`enterID`}
                type={`text`}
                value={enterID}
                errorMessage={error.enterID && error.enterID}
                onChange={(e) => {
                  setEnterId(e.target.value);
                  if (!e.target.value) {
                    return setError({
                      ...error,
                      enterID: `Email Is Required`,
                    });
                  } else {
                    return setError({
                      ...error,
                      enterID: "",
                    });
                  }
                }}
                onKeyPress={handleKeyPress}
              />
              <div className="mt-3 w-100">
                <Input
                  label={`Enter Password`}
                  id={`enterPassword`}
                  type={`password`}
                  value={password}
                  errorMessage={error.password && error.password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        password: `Password Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        password: "",
                      });
                    }
                  }}
                  onKeyPress={handleKeyPress}
                />
              </div>

              <div className="d-flex justify-content-between">
                <Button
                  btnName={"Demo Login"}
                  type={"button"}
                  onClick={handleDemoLogin}
                  newClass={"sign-btn"}
                />
             
                <Button
                  btnName={"Sign In"}
                  type={"button"}
                  onClick={handleSubmit}
                  newClass={"sign-btn "}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default connect(null, { login })(Login);
