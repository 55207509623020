import React, { useEffect, useState } from "react";
import { getAllUserData, userSwitch } from "../../../store/slice/userSlice";
import { useDispatch, useSelector } from "react-redux";
import Title from "../../extra/Title";
import Searching from "../../extra/Searching";
import Table from "../../extra/Table";
import Pagination from "../../extra/Pagination";
import { openDialog } from "../../../store/slice/dialogueSlice";
import { useNavigate } from "react-router-dom";
import ToggleSwitch from "../../extra/ToggleSwitch";
import { permissionError } from "../../utils/Alert";

const UserTable = () => {
  const { userData, total } = useSelector((state) => state.user);

  
  const hasPermission = useSelector(
    (state) => state.admin.admin.flag
  );

  console.log('hasPermission', hasPermission)

  const dispatch = useDispatch();
  const [start, setStart] = useState(1);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [isBlock, setIsBlock] = useState(false);
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const payload = {
      start,
      limit,
    };
    dispatch(getAllUserData(payload));
  }, [dispatch, start, limit]);

  useEffect(() => {
    setData(userData);
  }, [userData]);

  useEffect(() => {
    setIsBlock(userData?.isBlock);
  }, []);

  const handleFilterData = (filteredData) => {
    setFilteredData(filteredData);
  };

  const handlePageChange = (pageNumber) => {
    setStart(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setStart(1);
    setLimit(value);
  };

  const handleSwitch = (id) => {
    if (!hasPermission) return permissionError();
    if (id) {
      dispatch(userSwitch(id));
    }
  };

  const mapData = [
    {
      Header: "Index",
      body: "index",
      Cell: ({ index }) => (
        <span className="  text-nowrap">
          {(start - 1) * limit + parseInt(index) + 1}
        </span>
      ),
    },

    {
      Header: "Username",
      body: "username",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {row?.userName ? row?.userName : "-"}
        </span>
      ),
    },

    {
      Header: "Name",
      body: "name",
      Cell: ({ row }) => (
        <span className="  text-nowrap">{row?.firstName + row?.lastName}</span>
      ),
    },

    {
      Header: "Email",
      body: "email",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {row?.email !== "" ? row?.email : "-"}
        </span>
      ),
    },

    {
      Header: "Country",
      body: "country",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {row?.country ? row?.country : "-"}
        </span>
      ),
    },

    {
      Header: "Is Block",
      body: "Is Block",
      Cell: ({ row }) => (
        <ToggleSwitch
          value={row?.isBlock}
          onChange={() => handleSwitch(row?._id)}
        />
      ),
    },

  ];

  return (
    <div className="mainCustomAd hostTable">
      <div className="customAdTable">
        <div className="row">
          <div className="col-12 col-sm-2">
            <h6 style={{ marginTop: "10px", color: "#e1485c" }}>
              Total User: {userData?.length}
            </h6>
          </div>
        </div>
        <div className="row mt-3 pt-1">
          <div className="col-8">
            <Searching
              placeHolder={"What’re you searching for?"}
              type="client"
              buttonShow={true}
              data={userData}
              setData={setData}
              onFilterData={handleFilterData}
            />
          </div>
        </div>
        <Table
          data={data}
          type={"server"}
          mapData={mapData}
          start={start}
          limit={limit}
        />
        <Pagination
          type={"server"}
          start={start}
          limit={limit}
          totalData={total}
          setStart={setStart}
          setLimit={setLimit}
          handleRowsPerPage={handleRowsPerPage}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default UserTable;
