import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../store/slice/dialogueSlice";
import Button from "../extra/Button";
import Input from "../extra/Input";
import TextArea from "../extra/TextArea";
import {
  getFakeMessage,
  updatedFakeMessage,
} from "../../store/slice/hostSlice";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
} from "@mui/material";
import { permissionError } from "../utils/Alert";

const FakeMessageDialog = (props) => {
  const dispatch = useDispatch();
  const { dialogueData } = useSelector((state) => state.dialogue);
  const { fakeMessageData, fakeMessageId } = useSelector(
    (state) => state.hostData
  );

  const hasPermission = useSelector(
    (state) => state.admin.admin.flag
  );

  const [message, setMessage] = useState("");

  useEffect(() => {
    props.getFakeMessage(dialogueData?.type);
  }, [dialogueData]);

  useEffect(() => {
    const getMessage = fakeMessageData
      ? fakeMessageData?.join(",")
      : fakeMessageData;
    setMessage(getMessage);
  }, [fakeMessageData]);

  const handleSubmit = () => {
    if (!hasPermission) return permissionError();

    const payload = {
      data: {
        message: message,
        type: dialogueData?.type,
        messageId: fakeMessageId,
      },
    };
    props.updatedFakeMessage(payload);
    dispatch(closeDialog());
  };
  const handleClose = () => {
    dispatch(closeDialog());
  };
  return (
    <div className="gameDialogue fake-messageDialog">
      <div className="dialog">
        <div className="mainDiaogBox">
          <div className="dialog-head">
            <h4>Fake Message</h4>
            <IconButton onClick={handleClose}>
              <CancelIcon />
            </IconButton>
          </div>
          <form>
            <div className="dialog-body mb-3 pb-2">
              <h5>Note : Enter coma (,) separated string.</h5>
              <div className="row post-box">
                <div className="col-12">
                  <div className="input-post">
                    <TextArea
                      label={`Fake Message`}
                      onChange={(e) => setMessage(e.target.value)}
                      row={8}
                      value={message}
                      name={"Message"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row dialog-footer mt-2">
              <div className="col-6"></div>
              <div className="col-6 mt-2 d-flex align-items-center justify-content-end">
                <Button
                  btnName={"Cancel"}
                  onClick={handleClose}
                  style={{ marginRight: "10px" }}
                  newClass={"cancel-btn"}
                />
                <Button
                  btnName={"Submit"}
                  type={"button"}
                  onClick={handleSubmit}
                  newClass={"submit-btn"}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default connect(null, {
  getFakeMessage,
  updatedFakeMessage,
})(FakeMessageDialog);
