import React, { useState } from "react";
import { connect } from "react-redux";
import { Box, Tab, Tabs } from "@mui/material";
import Title from "../../extra/Title";
import { ReactComponent as GiftIcon } from "../../../assets/images/icon/GiftIcon.svg";
import { ReactComponent as GiftCategoryIcon } from "../../../assets/images/icon/GiftCategory.svg";

import GiftPage from "./GiftPage";
import GiftCategory from "./GiftCategory";

const GiftMain = (props) => {
  const getValue = localStorage.getItem("giftTable");
  const [value, setValue] = useState(Number(getValue) ? Number(getValue) : 0);

  const renderEditComponent = React.useCallback(() => {
    switch (value) {
      case 0:
        return <GiftCategory />;
      case 1:
        return <GiftPage />;
      default:
        break;
    }
  }, [value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem("giftTable", newValue);
  };

  return (
    <div className="giftPage">
      <Title title={"Gift"} />
      <div className="row">
        <div className="col-3 gift-tab mt-2">
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="icon tabs example"
            >
              <Tab icon={<GiftIcon />} iconPosition="start" label="Gift Category" />
              <Tab
                icon={<GiftCategoryIcon />}
                iconPosition="start"
                label="All Gift"
              />
            </Tabs>
          </Box>
        </div>
      </div>
      <div className="edit-renderComponent">{renderEditComponent()}</div>
    </div>
  );
};
export default connect(null, {})(GiftMain);
