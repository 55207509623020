import React, { useEffect, useState } from "react";
import Input from "../../extra/Input";
import ToggleSwitch from "../../extra/ToggleSwitch";
import { useDispatch, useSelector } from "react-redux";
import {
  appPaymentSwitch,
  getAppDetails,
  updateSetting,
} from "../../../store/slice/appSlice";
import Button from "../../extra/Button";
import { permissionError } from "../../utils/Alert";

const PaymentSetting = () => {
  const dispatch = useDispatch();
  const { appDetails } = useSelector((state) => state.app);
  const [stripePublishableKey, setStripePublishableKey] = useState("");
  const [stripeSecretKey, setStripeSecretKey] = useState("");
  const [razorpayId, setRazorpayId] = useState("");
  const [razorpayKey, setRazorpayKey] = useState("");
  const [isStripeSwitch, setIsStripePaySwitch] = useState(false);
  const [isRazorpaySwitch, setIsRazorPaySwitch] = useState(false);
  const [isgooglePlaySwitch, setIsGooglePlaySwitch] = useState(false);
  const hasPermission = useSelector(
    (state) => state.admin.admin.flag
  );

  const [error, setError] = useState({
    stripePublishableKey: "",
    stripeSecretKey: "",
    razorpayId: "",
    razorpayKey: "",
  });

  useEffect(() => {
    dispatch(getAppDetails());
  }, [dispatch]);

  useEffect(() => {
    if (appDetails) {
      setStripePublishableKey(appDetails?.stripePublishableKey || "");
      setStripeSecretKey(appDetails?.stripeSecretKey || "");
      setRazorpayId(appDetails?.razorpayId || "");
      setRazorpayKey(appDetails?.razorpayKey || "");
      setIsRazorPaySwitch(appDetails?.razorpaySwitch);
      setIsStripePaySwitch(appDetails?.stripeSwitch);
      setIsGooglePlaySwitch(appDetails?.googlePlaySwitch);
    }
  }, [appDetails]);

  const handleSwitch = (type) => {
    if (!hasPermission) return permissionError();

    const payload = {
      settingId: appDetails?._id,
      type: type,
    };
    dispatch(appPaymentSwitch(payload));
  };

  const handleSubmit = () => {
    if (!hasPermission) return permissionError();

    if (
      !stripePublishableKey ||
      !stripeSecretKey ||
      !razorpayId ||
      !razorpayKey
    ) {
      let error = {};
      if (!stripePublishableKey)
        error.stripePublishableKey = "Stripe publishable key is required !";
      if (!stripeSecretKey)
        error.stripeSecretKey = "Stripe secret key  is required !";
      if (!razorpayId) error.razorpayId = "Razorpay id is required !";
      if (!razorpayKey) error.razorpayKey = "razorpay key is required!";

      return setError({ ...error });
    } else {
      const settingData = {
        stripePublishableKey,
        stripeSecretKey,
        razorpayId,
        razorpayKey,
      };

      const payload = {
        settingData,
        settingId: appDetails?._id,
      };

      dispatch(updateSetting(payload));
    }
  };
  return (
    <>
      <div className="d-flex justify-content-end mt-2">
        <Button
          btnName={"Submit"}
          type={"submit"}
          newClass={"submit-btn"}
          onClick={handleSubmit}
        />
      </div>
      <div className="d-flex flex-wrap justify-content-between">
        <div className="custom-column mt-3 setting-box">
          <div className="d-flex justify-content-between">
            <h6 className="fw-bold">Razor Pay Setting</h6>

            <ToggleSwitch
              value={isRazorpaySwitch}
              onChange={setIsRazorPaySwitch}
              onClick={() => 

                handleSwitch("razorpaySwitch")
              }
            />
          </div>
          <div className="interstitial-ad-box">
            <Input
              label={`Razorpay Id`}
              id={`aazorpayId`}
              type={`text`}
              errorMessage={error.razorpayId && error.razorpayId}
              value={razorpayId}
              onChange={(e) => {
                setRazorpayId(e.target.value);
                if (!e.target.value) {
                  return setError({
                    ...error,
                    razorpayId: `Razor pay id is Required`,
                  });
                } else {
                  return setError({
                    ...error,
                    razorpayId: "",
                  });
                }
              }}
            />

            <Input
              label={`Razorpay Key`}
              id={`razorpayKey`}
              type={`text`}
              value={razorpayKey}
              errorMessage={error.razorpayKey && error.razorpayKey}
              onChange={(e) => {
                setRazorpayKey(e.target.value);
                if (!e.target.value) {
                  return setError({
                    ...error,
                    razorpayKey: `Razor pay key is required`,
                  });
                } else {
                  return setError({
                    ...error,
                    razorpayKey: "",
                  });
                }
              }}
            />
          </div>
        </div>

        <div className="custom-column mt-3 setting-box">
          <div className="d-flex justify-content-between">
            <h6 className="fw-bold">Stripe Pay Setting</h6>

            <ToggleSwitch
              value={isStripeSwitch}
              onChange={setIsStripePaySwitch}
              onClick={() => handleSwitch("stripeSwitch")}
            />
          </div>
          <div className="interstitial-ad-box">
            <Input
              label={`Stripe Publishable Key`}
              id={`stripePublishableKey`}
              type={`text`}
              errorMessage={
                error.stripePublishableKey && error.stripePublishableKey
              }
              value={stripePublishableKey}
              onChange={(e) => {
                setStripePublishableKey(e.target.value);
                if (!e.target.value) {
                  return setError({
                    ...error,
                    stripePublishableKey: `Stripe publishable key is required`,
                  });
                } else {
                  return setError({
                    ...error,
                    stripePublishableKey: "",
                  });
                }
              }}
            />

            <Input
              label={`Stripe Secret Key`}
              id={`stripeSecretKey`}
              type={`text`}
              errorMessage={error.stripeSecretKey && error.stripeSecretKey}
              value={stripeSecretKey}
              onChange={(e) => {
                setStripeSecretKey(e.target.value);
                if (!e.target.value) {
                  return setError({
                    ...error,
                    stripeSecretKey: `Stripe secret key is required`,
                  });
                } else {
                  return setError({
                    ...error,
                    stripeSecretKey: "",
                  });
                }
              }}
            />
          </div>
        </div>

        <div className="custom-column mt-3 setting-box">
          <div className="d-flex justify-content-between">
            <h6 className="fw-bold">Google Play Setting</h6>

            <ToggleSwitch
              value={isgooglePlaySwitch}
              onChange={setIsGooglePlaySwitch}
              onClick={() => handleSwitch("googlePlaySwitch")}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentSetting;
