import React, { useEffect, useState, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Input from "../../extra/Input";
import AdRevenueBox from "../../extra/AdRevenueBox";
import {
  getMonetization,
  customAdSubmit,
  removeCustomAd,
  appMonetizationSwitch,
  monetizationUpdate,
} from "../../../store/slice/appSlice";
import { useLocation } from "react-router-dom";
import Button from "../../extra/Button";
import { permissionError } from "../../utils/Alert";

const Monetization = (props) => {
  const location = useLocation();
  const { monetizationData } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const hasPermission = useSelector((state) => state.admin.admin.flag);
  const [interstitialAd, setInterstitialAd] = useState("");
  const [switchDataType, setSwitchDataType] = useState(true);
  localStorage?.setItem("monetizationId", monetizationData?._id);
  const monetizationId = localStorage?.getItem("monetizationId");

  const [isGoogle1, setIsGoogle1] = useState(false);
  const [googleAd1, setGoogleAd1] = useState({
    banner: "",
    native: "",
    interstitial: "",
    appOpen: "",
    reward: "",
  });

  const fetchMonetizationData = useCallback(() => {
    if (monetizationId) {
      dispatch(getMonetization(monetizationId));
    }
  }, [monetizationId, dispatch]);

  useEffect(() => {
    fetchMonetizationData();
  }, [fetchMonetizationData]);

  useEffect(() => {
    setGoogleAd1({
      banner: monetizationData?.google1?.bannerGoogle1,
      native: monetizationData?.google1?.nativeGoogle1,
      interstitial: monetizationData?.google1?.interstitialGoogle1,
      appOpen: monetizationData?.google1?.appOpenGoogle1,
      reward: monetizationData?.google1?.rewardGoogle1,
    });
    setIsGoogle1(monetizationData?.google1?.isGoogle1);

    setInterstitialAd(monetizationData?.interstitialAdClick);
  }, [monetizationData]);

  const handleSwitch = (id, type, value) => {
    if (!hasPermission) return permissionError();

    const payload = {
      monetizationId: monetizationId,
      type: type,
      value: value,
    };
    props.appMonetizationSwitch(payload);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!hasPermission) return permissionError();

    let data = {
      interstitialAdClick: interstitialAd,
      bannerGoogle1: googleAd1?.banner,
      nativeGoogle1: googleAd1?.native,
      interstitialGoogle1: googleAd1?.interstitial,
      appOpenGoogle1: googleAd1?.appOpen,
      rewardGoogle1: googleAd1?.reward,
      appId: location?.state?.editAppData?._id,
    };
    let payload = {
      data: data,
      appMonetizationId: monetizationId,
    };
    props.monetizationUpdate(payload);
  };

  useEffect(() => {
    sessionStorage.setItem("googleAd1", JSON.stringify(googleAd1));
  }, [googleAd1]);

  return (
    <div className="monetization-edit">
      <form>
        <div className="ad-level">
          <div className="d-flex justify-content-end align-items-center">
            <div className="mt-2">
              <Button
                btnName={"Submit"}
                type={"submit"}
                newClass={"submit-btn"}
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
        <div className="add-revenue">
          <div className="row">
            <div className="col-12 mt-3">
              <div className="row" style={{ position: "relative" }}>
                <div className="col-12 mb-4 pb-4 col-md-6 mb-md-0 pb-md-0 ">
                  <AdRevenueBox
                    title={"Google Ad"}
                    inputValue={googleAd1}
                    setInputValue={setGoogleAd1}
                    switchValue={isGoogle1}
                    setSwitchDataType={setSwitchDataType}
                    dataNameTransfer={"isGoogle1"}
                    margin={"0px 10px 0px 0px"}
                    marginShow={true}
                    switchOnChange={setIsGoogle1}
                    onClick={() =>
                      handleSwitch(
                        location?.state?.editAppData?._id,
                        "isGoogle1",
                        isGoogle1
                      )
                    }
                  />
                </div>

                <div className="col-12 col-md-6 ad-revenue-box height">
                  <div className="interstitial-ad-box">
                    <Input
                      label={`Interstitial Ad Click`}
                      id={`interstitialAd`}
                      type={`text`}
                      value={interstitialAd}
                      onChange={(e) => {
                        setInterstitialAd(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default connect(null, {
  getMonetization,
  customAdSubmit,
  removeCustomAd,
  appMonetizationSwitch,
  monetizationUpdate,
})(Monetization);
