/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Title from "../extra/Title";
import { Box } from "@mui/material";
import { ReactComponent as MonetizationIcon } from "../../assets/images/icon/MonetizationIcon.svg";
import { ReactComponent as OtherSettingIcon } from "../../assets/images/icon/OtherSetting.svg";
import AppsIcon from "@mui/icons-material/Apps";
import PaymentIcon from '@mui/icons-material/Payment';
import OtherSetting from "./EditApp/OtherSetting";
import { useLocation } from "react-router-dom";
import { baseURL } from "../utils/config";
import { useSelector } from "react-redux";
import MonetiztionMain from "./EditApp/MonetiztionMain";
import Setting from "./EditApp/Setting";
import PaymentSetting from "./EditApp/PaymentSetting";

const EditApp = () => {
  const location = useLocation();
  const getValue = localStorage.getItem("appEdit");
  const { appDetails } = useSelector((state) => state.app);
  const [showAppLogo, setShowAppLogo] = useState("");
  const [appName, setAppName] = useState("");
  const [value, setValue] = useState(Number(getValue) ? Number(getValue) : 0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem("appEdit", newValue);
  };

  const renderEditComponent = React.useCallback(() => {
    switch (value) {
      case 0:
        return <Setting />;
        case 1:
          return <PaymentSetting />;
      case 2:
        return <MonetiztionMain />;
      case 3:
        return <OtherSetting />;

      default:
        break;
    }
  }, [value]);

  useEffect(() => {
    if (appDetails && Object.values(appDetails)?.length > 0) {
      localStorage.setItem("appHead", JSON.stringify(appDetails));
    }
    const dataGet = JSON.parse(localStorage.getItem("appHead"));

    if (dataGet && Object.values(dataGet)?.length > 0) {
      setShowAppLogo(baseURL + dataGet?.appLogo);
      setAppName(dataGet?.appName);
    } else {
      setShowAppLogo(baseURL + appDetails?.appLogo);
      setAppName(appDetails?.appName);
    }
  }, [appDetails]);

  return (
    <div className="new-appEdit">
      <div className="app-edit-body">
        <div className="top-head-edit">
          <Title title={"Setting"} />
          <div className="app-nameDetails">
            <img src={showAppLogo ? showAppLogo : ""} alt="" />
            <h6>{appName ? appName : "-"}</h6>
          </div>
        </div>
        <Box sx={{ width: "100%" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="icon tabs example"
          >
            <Tab icon={<AppsIcon />} iconPosition="start" label="Setting" />
            <Tab icon={<PaymentIcon />} iconPosition="start" label="Payment Setting" />

            <Tab
              icon={<MonetizationIcon />}
              iconPosition="start"
              label="Monetization"
            />

            <Tab
              icon={<OtherSettingIcon />}
              iconPosition="start"
              label="Other Setting"
            />
          </Tabs>
        </Box>
      </div>
      <div className="edit-renderComponent">{renderEditComponent()}</div>
    </div>
  );
};

export default EditApp;
