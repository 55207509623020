import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../store/slice/dialogueSlice";
import Button from "../extra/Button";
import Input from "../extra/Input";
import {
  hostCreate,
  updatedHost,
  deleteHostImage,
} from "../../store/slice/hostSlice";
import CancelIcon from "@mui/icons-material/Cancel";
import { IconButton } from "@mui/material";
import { baseURL } from "../utils/config";
import noImage from "../../assets/images/noImage.jpg";
import { Height } from "@mui/icons-material";
import { complainSolved } from "../../store/slice/complainSlice";
import { permissionError } from "../utils/Alert";


const ComplainActionDialog = (props) => {
  const dispatch = useDispatch();

  const {  dialogueData } = useSelector(
    (state) => state.dialogue
  );
  



  const handleOpenUrl = (data) => {
    window.open(data, "_blank");
  };

  const handleSubmit = () => {

    if(dialogueData?.id){

        dispatch(complainSolved(dialogueData?.id))
    }
    dispatch(closeDialog())

  };

  return (
    <div className="host-dialog">
      <div class="dialog">
        <div class="mainDiaogBox">
          <div className="dialog-head d-flex justify-content-between">
            <h4>{ "Solve Complain"}</h4>
            <IconButton onClick={() => dispatch(closeDialog())}>
              <CancelIcon />
            </IconButton>
          </div>
          <form>
            <div className="dialog-body modal-body">
              Are you sure you want to solve a pending complain??
            </div>
            <div className="row dialog-footer mt-3">
              <div className="col-6"></div>
              <div className="col-6 mt-2 d-flex align-items-center justify-content-end">
                <Button
                  btnName={"Cancel"}
                  onClick={() => dispatch(closeDialog())}
                  style={{ marginRight: "10px" }}
                  newClass="cancel-btn"
                />
                <Button
                  btnName={"Submit"}
                  onClick={handleSubmit}
                  type={"button"}
                  newClass="submit-btn"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default connect(null, {
  hostCreate,
  updatedHost,
  deleteHostImage,
})(ComplainActionDialog);
