import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


let currentToast = null;

export const setToast = (type, data) => {
  if (currentToast) {
    toast.update(currentToast, {
      render: data,
      type,
      autoClose: 2000,
    });
  } else {
    currentToast = toast(data, {
      type,
      autoClose: 2000,
      position: "top-right",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      rtl: false,
      onClose: () => {
        currentToast = null;
      },
    });
  }
};


 