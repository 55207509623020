import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../store/slice/dialogueSlice";

import {
  hostCreate,
  updatedHost,
  deleteHostImage,
} from "../../store/slice/hostSlice";
import CancelIcon from "@mui/icons-material/Cancel";
import { IconButton } from "@mui/material";
import { baseURL } from "../utils/config";
import noImage from "../../assets/images/noImage.jpg";
import { getComplainInfo } from "../../store/slice/complainSlice";

const Complaininfo = (props) => {
  const dispatch = useDispatch();

  const {  dialogueData  } = useSelector(
    (state) => state.dialogue
  );
  const { complainInfo } = useSelector((state) => state.complain);


  useEffect(() => {
    if (dialogueData) {
      dispatch(getComplainInfo(dialogueData?.data));
    }
  }, [dispatch, dialogueData]);


  const handleOpenUrl = (data) => {
    window.open(data, "_blank");
  };

  return (
    <div className="host-dialog">
      <div class="dialog">
        <div class="mainDiaogBox">
          <div className="dialog-head d-flex justify-content-between">
            <h4>{dialogueData ? "Complain Details" : "Create Host"}</h4>
            <IconButton onClick={() => dispatch(closeDialog())}>
              <CancelIcon />
            </IconButton>
          </div>
          <form>
            <div className="dialog-body modal-body">
              <div className="row">
              <div className="mx-auto">
                <tr className="d-flex align-items-center">
                  <td className="py-2 mb-2 feedback__title complain-width">
                    Complain Image
                  </td>
                  <td className="py-2 mb-2 feedback__title">
                    &nbsp;:&nbsp;&nbsp;
                  </td>
                  <td className="py-2 mb-2 feedback__details">
                    <img
                      height="100px"
                      width="100px"
                      alt="app"
                      src={
                        complainInfo?.image && !complainInfo?.image?.includes("\\")
                          ? baseURL + complainInfo?.image
                          : noImage
                      }
                      style={{
                        boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                        border: "2px solid #fff",
                        borderRadius: 10,
                        float: "left",
                        objectFit: "cover",
                        cursor: "pointer",
                      }}
                      onClick={() => handleOpenUrl(complainInfo?.image)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="py-2 mb-2 feedback__title complain-width">
                    User Name{" "}
                  </td>
                  <td className="py-2 mb-2 feedback__title">
                    &nbsp;:&nbsp;&nbsp;&nbsp;
                  </td>
                  <td className="py-2 mb-2 feedback__details">
                    {complainInfo?.userId?.userName
                      ? complainInfo?.userId?.userName
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <td className="py-2 mb-2 feedback__title complain-width">
                    User's Country{" "}
                  </td>
                  <td className="py-2 mb-2 feedback__title">
                    &nbsp;:&nbsp;&nbsp;&nbsp;
                  </td>
                  <td className="py-2 mb-2 feedback__details">
                    {complainInfo?.userId?.country
                      ? complainInfo?.userId?.country
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <td className="py-2 mb-2 feedback__title complain-width">
                    Contact{" "}
                  </td>
                  <td className="py-2 mb-2 feedback__title">
                    &nbsp;:&nbsp;&nbsp;&nbsp;
                  </td>
                  <td className="py-2 mb-2 feedback__details">
                    {complainInfo?.contact ? complainInfo?.contact : "-"}
                  </td>
                </tr>

                <tr>
                  <td className="py-2 mb-2 feedback__title complain-width">
                    Complain Message{" "}
                  </td>
                  <td className="py-2 mb-2 feedback__title">
                    &nbsp;:&nbsp;&nbsp;&nbsp;
                  </td>
                  <td className="py-2 mb-2 feedback__details">
                    {complainInfo?.message ? complainInfo?.message : "-"}
                  </td>
                </tr>

                <tr>
                  <td className="py-2 mb-2 feedback__title  complain-width">
                    Complain Status
                  </td>
                  <td className="py-2 mb-2 feedback__title">
                    &nbsp;:&nbsp;&nbsp;&nbsp;
                  </td>
                  <td className="py-2 mb-2 feedback__details">
                    {complainInfo?.isSolved ? (
                      <div className="badge bg-success mt-2 p-2 text-dark">
                        Solved
                      </div>
                    ) : (
                      <div className="badge bg-warning mt-2 p-2 text-dark">
                        Pending
                      </div>
                    )}
                  </td>
                </tr>
              </div>
            </div>
      </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default connect(null, {
  hostCreate,
  updatedHost,
  deleteHostImage,
})(Complaininfo);
