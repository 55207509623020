import React, { useEffect, useState } from "react";
import { ReactComponent as SearchIcon } from "../../assets/images/icon/SearchIcon.svg";

export default function Searching(props) {
  let { placeHolder, type, data, setData, search, buttonShow } = props;
  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (value) => {
    const getLowerCaseSearch = 
      value?.toLowerCase()?.trim();


    if (getLowerCaseSearch !== undefined) {
      if (type === "client") {
        if (getLowerCaseSearch) {
          const filteredData = data.filter((item) => {
            return Object.keys(item).some((key) => {
              if (key === "_id" || key === "updatedAt" || key === "createdAt") {
                return false;
              }
              const itemValue = item[key];
              if (typeof itemValue === "string") {
                return itemValue.toLowerCase().indexOf(getLowerCaseSearch) > -1;
              } else if (typeof itemValue === "number") {
                return itemValue.toString().indexOf(getLowerCaseSearch) > -1;
              } else if (typeof itemValue === "object" && itemValue !== null) {
                // Check for nested object (agencyId) and handle nested properties
                return Object.values(itemValue).some((nestedValue) => {
                  if (typeof nestedValue === "string") {
                    return (
                      nestedValue.toLowerCase().indexOf(getLowerCaseSearch) > -1
                    );
                  }
                  return false;
                });
              }
              return false;
            });
          });
          setData(filteredData);
        } else {
          setData(data);
        }
      } else {
      }
    }
  };

  useEffect(() => {
    if (searchValue === "") {
      setData(data);
    }
  }, [searchValue]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearch(e);
    }
  };

  return (
    <div className="custom-searching">
      <div className={`${buttonShow ? "showButtonInput" : "showIcon"}`}>
        {buttonShow === false && <SearchIcon />}
        <input
          type="text"
          placeholder={placeHolder}
          onKeyPress={handleKeyPress}
          onChange={(e) => {
            handleSearch(e?.target?.value);
          }}
        />
        {buttonShow && (
          <button onClick={(e) => handleSearch(e)}>
            <SearchIcon />
          </button>
        )}
      </div>
    </div>
  );
}
