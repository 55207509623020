/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { getHostTwo, deleteHost } from "../../store/slice/hostSlice";
import { connect, useDispatch, useSelector } from "react-redux";
import Table from "../extra/Table";
import Pagination from "../extra/Pagination";
import { baseURL } from "../utils/config";
import Title from "../extra/Title";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import Searching from "../extra/Searching";
import dayjs from "dayjs";
import Button from "../extra/Button";
import { permissionError, warning } from "../utils/Alert";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { openDialog } from "../../store/slice/dialogueSlice";
import MessageIcon from "@mui/icons-material/Message";
import FakeMessageDialog from "../dialogComponent/FakeMessageDialog";
import FakeCommentDialog from "../dialogComponent/FakeCommentDialog";
import HostDialogTwo from "../dialogComponent/HostDialogTwo";

const HostTableTwo = () => {
  const { hostData, hostTotal } = useSelector((state) => state.hostData);
  const { dialogueType } = useSelector((state) => state.dialogue);
  const [start, setStart] = useState(1);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const dispatch = useDispatch();
  
  const hasPermission = useSelector(
    (state) => state.admin.admin.flag
  );


  const mapData = [
    {
      Header: "Index",
      body: "index",
      Cell: ({ index }) => (
        <span className="  text-nowrap">
          {(start - 1) * limit + parseInt(index) + 1}
        </span>
      ),
    },
    {
      Header: "Image",
      body: "image",
      Cell: ({ row }) => (
        <div className="image-showTable mx-auto">
          {row?.image?.map((item) => {
            return (
              <>
                <img src={row?.imageType === 2 ?  baseURL +  item : item} />
              </>
            );
          })}
        </div>
      ),
    },
    {
      Header: "Video",
      body: "video",
      Cell: ({ row }) => (
        <video
          controls
          height="80"
          width="80"
          src={row?.videoType === 2 ? baseURL + row?.video : row?.video}
          style={{ borderRadius: "10px" }}
        />
      ),
    },
    {
      Header: "Name",
      body: "name",
      Cell: ({ row }) => <span className="  text-nowrap">{row?.name}</span>,
    },
    {
      Header: "Bio",
      body: "bio",
      Cell: ({ row }) => <p className="host-bio-text mb-0">{row?.bio}</p>,
    },
    {
      Header: "Edit",
      body: "edit",
      Cell: ({ row }) => (
        <Button
          onClick={() =>
            dispatch(
              openDialog({ dialogueType: "editHost", dialogueData: row })
            )
          }
          btnIcon={<EditRoundedIcon />}
          newClass="editBtn"
        />
      ),
    },
    {
      Header: "Delete",
      body: "delete",
      Cell: ({ row }) => (
        <Button
          btnIcon={<DeleteRoundedIcon />}
          newClass="deleteBtn"
          onClick={() => handleDeleteApp(row?._id)}
        />
      ),
    },
    {
      Header: "Created At	",
      body: "createdAt",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {dayjs(row?.createdAt).format("DD MMM YYYY")}
        </span>
      ),
    },
  ];

  const handleDeleteApp = (id) => {
    if (!hasPermission) return permissionError();

    const data = warning();
    data
      .then((res) => {
        if (res) {
          if (res) {
            dispatch(deleteHost(id));
          }
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    let payload = {
      start: start,
      limit: limit,
    };
    dispatch(getHostTwo(payload));
  }, [start, limit]);

  useEffect(() => {
    setData(hostData);
  }, [hostData]);

  const handlePageChange = (pageNumber) => {
    setStart(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setStart(1);
    setLimit(value);
  };

  const handleFilterData = (filteredData) => {
    setFilteredData(filteredData);
  };

  const handleNewOpenDialog = (type) => {
    if (!hasPermission) return permissionError();

    const payload = {
      dialogue: true,
      dialogueType: type,
      dialogueData: null,
    };
    dispatch(openDialog(payload));
  };

  return (
    <div className="mainCustomAd hostTable">
      <Title title="Host Table" />
      {(dialogueType === "createHost" || dialogueType === "editHost") && (
        <HostDialogTwo />
      )}
      {dialogueType === "messageDialog" && <FakeMessageDialog />}
      {dialogueType === "commentDialog" && <FakeCommentDialog />}
      <div className="customAdTable">
        <div className="row">
          <div className="col-12 col-sm-2">
            <h6 style={{ marginTop: "10px" }}>
              Total Host: {hostData?.length}
            </h6>
          </div>

          <div className="col-12 col-sm-10 d-flex justify-content-end">
            <Button
              btnName={"Fake Message"}
              btnIcon={<MessageIcon />}
              type={"button"}
              onClick={() =>
                dispatch(
                  openDialog({
                    dialogueType: "messageDialog",
                    dialogueData: { type: 1 },
                  })
                )
              }
              newClass={"add-btnnew fake-messageBtn"}
            />
          </div>
        </div>
        <div className="row mt-3 pt-1">
          <div className="col-8">
            <Searching
              placeHolder={"What’re you searching for?"}
              type="client"
              buttonShow={true}
              data={hostData}
              setData={setData}
              onFilterData={handleFilterData}
            />
          </div>
          <div className="col-4 new-button d-flex justify-content-end align-items-center">
            <Button
              btnName={"New"}
              btnIcon={<AddRoundedIcon />}
              type={"button"}
              onClick={() => handleNewOpenDialog("createHost")}
              newClass={"add-btn"}
            />
          </div>
        </div>
        <Table
          data={data}
          type={"server"}
          mapData={mapData}
          start={start}
          limit={limit}
        />
        <Pagination
          type={"server"}
          start={start}
          limit={limit}
          totalData={hostTotal}ha
          setStart={setStart}
          setLimit={setLimit}
          handleRowsPerPage={handleRowsPerPage}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};
export default connect(null, { getHostTwo, deleteHost })(HostTableTwo);
