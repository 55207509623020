/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import Pagination from "../../extra/Pagination";
import { isSkeleton } from "../../utils/allSelector";
import { baseURL } from "../../utils/config";
import Searching from "../../extra/Searching";
import { getPendingComplain } from "../../../store/slice/complainSlice";
import noImage from "../../../assets/images/noImage.jpg";

const Row = () => (
  <div className="row">
    <div className="skeleton-table"></div>
    <div className="skeleton-table"></div>
    <div className="skeleton-table"></div>
    <div className="skeleton-table"></div>
    <div className="skeleton-table"></div>
  </div>
);

const SolvedComplain = ({ type }) => {
  const [data, setData] = useState([]);
  const [tableValues, setTableValues] = useState([]);
  const [start, setStart] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState();
  const isSkeletonLoader = useSelector(isSkeleton);
  const [skeleton, setSkeleton] = useState();
  const numberOfRows = 10;
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const { pendingComplain } = useSelector((state) => state.complain);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPendingComplain(type));
  }, [dispatch]);

  useEffect(() => {
    const renderedRows = [...Array(numberOfRows)]?.map((e, i) => (
      <div>
        <Row />
      </div>
    ));
    setSkeleton(renderedRows);
  }, [numberOfRows]);

  useEffect(() => {
    setData(pendingComplain);
  }, [pendingComplain]);

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handlePageChange = (pageNumber) => {
    setStart(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setStart(1);
    setLimit(value);
  };

  return (
    <div className="shortVideo">
      <div className="customAdTable">
        <h6 style={{ marginTop: "10px" }}>
          Solved : {pendingComplain?.length}
        </h6>
        <div className="row mt-3 pt-1">
          <div className="col-8">
            <Searching
              placeHolder={"What’re you searching for?"}
              type="client"
              buttonShow={true}
              data={tableValues}
              setData={setData}
              onFilterData={handleFilterData}
              searchValue={search}
            />
          </div>
        </div>
        <div className="simple-table">
          <table>
            <thead>
              <tr>
                <th>
                  <span>Index</span>
                </th>
                <th>
                  <span>Image</span>
                </th>
                <th>
                  <span>Message</span>
                </th>
                <th>
                  <span>Contact</span>
                </th>

                <th>
                  <span>Created At</span>
                </th>
              </tr>
            </thead>
            {isSkeletonLoader === true ? (
              <tbody>
                <tr>
                  <td>{skeleton}</td>
                  <td>{skeleton}</td>
                  <td>{skeleton}</td>
                  <td>{skeleton}</td>
                  <td>{skeleton}</td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {data?.length > 0 &&
                  data?.map((item, index) => {
                    const adjustedIndex = index + (start - 1) * limit + 1;
                    return (
                      <tr>
                        <td>
                          <span>{adjustedIndex}</span>
                        </td>
                        <td>
                          <img
                            src={
                              item?.image && !item.image.includes("\\")
                                ? baseURL + item.image
                                : noImage
                            }
                            alt={`ServiceImage${index}`}
                            className="form-input"
                            draggable="false"
                            width={"60px"}
                            height={"60px"}
                          />
                        </td>
                        <td>
                          <span>{item?.contact}</span>
                        </td>
                        <td>
                          <span>{item?.message}</span>
                        </td>

                        <td>
                          <span className="text-nowrap">
                            {dayjs(item?.createdAt)?.format("DD MMM YYYY")}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};
export default SolvedComplain;
