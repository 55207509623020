import React, { useEffect, useState } from "react";
import {
  getVipPlan,
  planUpdate,
  deleteVipPlan,
  isActiveVipPlan,
} from "../../store/slice/appSlice";
import { connect, useDispatch, useSelector } from "react-redux";
import Table from "../extra/Table";
import Pagination from "../extra/Pagination";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import ToggleSwitch from "../extra/ToggleSwitch";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { useLocation } from "react-router-dom";
import { permissionError, warning } from "../utils/Alert";
import Button from "../extra/Button";
import { openDialog } from "../../store/slice/dialogueSlice";
import VipPlanDialog from "../dialogComponent/VipPlanDialog";
import Title from "../extra/Title";

const VipPlanTables = (props) => {
  const [data, setData] = useState([]);
  const [start, setStart] = useState(1);
  const [limit, setLimit] = useState(10);
  const location = useLocation();
  const dispatch = useDispatch();
  const { vipPlanData, totalVipPlan } = useSelector((state) => state.app);
  const { dialogue, dialogueType } = useSelector(
    (state) => state.dialogue
  );
  const hasPermission = useSelector(
    (state) => state.admin.admin.flag
  );

  const handlePageChange = (pageNumber) => {
    setStart(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setStart(1);
    setLimit(value);
  };

  useEffect(() => {
    const payload = {
      appId: location?.state?.editAppData?._id,
      start,
      limit,
    };
    props.getVipPlan(payload);
  }, [start, limit]);

  useEffect(() => {
    setData(vipPlanData);
  }, [vipPlanData]);

  const handleEditPlan = (row, type) => {
    if (!hasPermission) return permissionError();

    const payload = {
      dialogue: true,
      dialogueType: type,
      dialogueData: row,
    };
    dispatch(openDialog(payload));
  };

  const handleDeltePlan = (row) => {
    if (!hasPermission) return permissionError();

    const data = warning();
    data
      .then((res) => {
        if (res) {
          if (res) {
            props.deleteVipPlan(row);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const handleNewOpenDialog = (type) => {
    if (!hasPermission) return permissionError();

    const payload = {
      dialogue: true,
      dialogueType: type,
      dialogueData: null,
    };
    dispatch(openDialog(payload));
  };

  const handleInActiveSwitch = (row) => {
    if (!hasPermission) return permissionError();

    const payload = {
      id: row?._id,
      value: row?.isActive,
    };
    props.isActiveVipPlan(payload);
  };

  const handlePaymentSwitch = (row, type) => {
    const payload = {
      planId: row?._id,
      appId: location?.state?.editAppData?._id,
      type: type,
      value: row?.isActive,
    };
    props.paymentPlanSwitch(payload);
  };
  const mapData = [
    {
      Header: "Index",
      Cell: ({ index }) => (
        <span className="  text-nowrap">
          {(start - 1) * limit + parseInt(index) + 1}
        </span>
      ),
    },
    {
      Header: "Name",
      Cell: ({ row }) => (
        <span className="  text-nowrap">{row?.name ? row?.name : "-"}</span>
      ),
    },

    {
      Header: "Validity",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {row?.validity ? row?.validity : "-"}
        </span>
      ),
    },
    {
      Header: "Validity Type",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {row?.validityType ? row?.validityType : "-"}
        </span>
      ),
    },

    {
      Header: "Dollar",
      Cell: ({ row }) => (
        <span className="  text-nowrap">{row?.dollar ? row?.dollar : "-"}</span>
      ),
    },
    {
      Header: "Rupee",
      Cell: ({ row }) => (
        <span className="  text-nowrap">{row?.rupee ? row?.rupee : "-"}</span>
      ),
    },
    {
      Header: "Discount",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {row?.discount ? row?.discount : "-"}
        </span>
      ),
    },
    {
      Header: "Product Key",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {row?.productKey ? row?.productKey : "-"}
        </span>
      ),
    },
    {
      Header: "Note",
      Cell: ({ row }) => (
        <span
          style={{
            width: "80px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "block",
          }}
        >
          {row?.note ? row?.note : "-"}
        </span>
      ),
    },
    {
      Header: "Is Active",
      body: "isActive",
      Cell: ({ row }) => (
        <div>
          <ToggleSwitch
            leftLabel={""}
            rightLabel={""}
            onChange={() => handleInActiveSwitch(row)}
            value={row?.isActive}
          />
        </div>
      ),
    },
    {
      Header: "Edit",
      body: "edit",
      Cell: ({ row }) => (
        <Button
          btnIcon={<EditRoundedIcon />}
          newClass={"editBtn"}
          onClick={() => handleEditPlan(row, "newVipPlan")}
        />
      ),
    },
    {
      Header: "Delete",
      body: "delete",
      Cell: ({ row }) => (
        <Button
          btnIcon={<DeleteRoundedIcon />}
          onClick={() => handleDeltePlan(row?._id)}
          newClass={"delete-btn-plan"}
        />
      ),
    },
  ];

  return (
    <div className="mainCustomAd">
      <Title title="Vip Plan" />
      {dialogue && dialogueType === "newVipPlan" && <VipPlanDialog />}
      <div className="customAdTable">
        <h6 style={{ marginTop: "10px" }}>
          Total Vip Plan : {vipPlanData?.length}
        </h6>

        <div className="plan-table">
          <div className="new-button d-flex justify-content-end mt-2">
            <Button
              btnName={"New"}
              btnIcon={<AddRoundedIcon />}
              type={"button"}
              onClick={() => handleNewOpenDialog("newVipPlan")}
              newClass={"new-btn"}
            />
          </div>
          <Table
            data={data}
            type={"server"}
            mapData={mapData}
            start={start}
            limit={limit}
          />
          <Pagination
            type={"server"}
            start={start}
            limit={limit}
            totalData={totalVipPlan}
            setStart={setStart}
            setLimit={setLimit}
            handleRowsPerPage={handleRowsPerPage}
            handlePageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};
export default connect(null, {
  getVipPlan,
  planUpdate,
  deleteVipPlan,
  isActiveVipPlan,
})(VipPlanTables);
