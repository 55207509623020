import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../Component/Api/axiosApi";

const initialState = {
  purchasePlan: [],
  isLoading: false,
  isSkeleton: false,
  total: null,
};

export const getPurchasePlan = createAsyncThunk(
  "admin/user/getUsers",
  async (payload) => {
    return apiInstance.get(
      `admin/vipPlan/fetchVipPlanPurchaseHistory?start=${payload?.start}&limit=${payload?.limit}`
    );
  }
);



const purchasePlanSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPurchasePlan.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getPurchasePlan.fulfilled, (state, action) => {
      state.isSkeleton = false;
      state.purchasePlan = action?.payload?.history;
      state.total = action?.payload?.totalUser;
    });

    builder.addCase(getPurchasePlan.rejected, (state, action) => {
      state.isSkeleton = false;
    });

  },
});
export default purchasePlanSlice.reducer;
