import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getAdmin,
  getDashboardCount,
  dashboardAnalyticUserData,
  dashboardAnalyticRevnueData,
} from "../../store/slice/authSlice";
import Title from "../extra/Title";
import DateRange from "../extra/DateRangePicker";
import dayjs from "dayjs";
import ApexCharts from "react-apexcharts";
import { useNavigate } from "react-router-dom";

function Dashboard(props) {
  const { dashboardCount, dashData, dashUserData, dashRevnueData } =
    useSelector((state) => state.admin);
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState("All");
  const [endDate, setEndDate] = useState("All");
  const [startDate1, setStartDate1] = useState("All");
  const [endDate1, setEndDate1] = useState("All");
  const [data, setData] = useState([]);

  const startDateFormat = (startDate) => {
    return startDate && dayjs(startDate).isValid()
      ? dayjs(startDate).format("YYYY-MM-DD")
      : dayjs().subtract(7, "day").format("YYYY-MM-DD");
  };
  const endDateFormat = (endDate) => {
    return endDate && dayjs(endDate).isValid()
      ? dayjs(endDate).format("YYYY-MM-DD")
      : dayjs().format("YYYY-MM-DD");
  };

  const startDateData = startDateFormat(startDate);
  const endDateData = endDateFormat(endDate);

  const startDateFormat1 = (startDate) => {
    return startDate && dayjs(startDate).isValid()
      ? dayjs(startDate).format("YYYY-MM-DD")
      : dayjs().subtract(7, "day").format("YYYY-MM-DD");
  };
  const endDateFormat1 = (endDate) => {
    return endDate && dayjs(endDate).isValid()
      ? dayjs(endDate).format("YYYY-MM-DD")
      : dayjs().format("YYYY-MM-DD");
  };

  const startDateData1 = startDateFormat1(startDate1);
  const endDateData1 = endDateFormat1(endDate1);

  const totalUser = dashUserData?.reduce((acc, value) => acc + value.count, 0);
  const totalRevenue = dashRevnueData?.reduce(
    (acc, value) => acc + value.count,
    0
  );

  useEffect(() => {
    props.getDashboardCount();
  }, []);

  useEffect(() => {
    setData(dashData);
  }, [dashData]);

  return (
    <div className="dashboardPage appUser">
      <h3>Welcome Admin !</h3>
      <Title title={"Dashboard"} dashboardTitle={true} />

      <div className="row g-3 d-flex">
        <div className="col-xl-3 col-md-6 col-12">
          <div className="dashboard-card">
            <div className="dashboard-card-left">
              <h6 onClick={() => navigate("/admin/usertable")}>Total User</h6>
              <h4>{dashboardCount ? dashboardCount?.totalUser : "0"}</h4>
            </div>

            <div>
              <svg
                width="62"
                height="44"
                viewBox="0 0 62 44"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.3 18.6C12.7197 18.6 15.5 15.8197 15.5 12.4C15.5 8.98031 12.7197 6.2 9.3 6.2C5.88031 6.2 3.1 8.98031 3.1 12.4C3.1 15.8197 5.88031 18.6 9.3 18.6ZM52.7 18.6C56.1197 18.6 58.9 15.8197 58.9 12.4C58.9 8.98031 56.1197 6.2 52.7 6.2C49.2803 6.2 46.5 8.98031 46.5 12.4C46.5 15.8197 49.2803 18.6 52.7 18.6ZM55.8 21.7H49.6C47.895 21.7 46.3547 22.3878 45.2309 23.5019C49.135 25.6428 51.9056 29.5081 52.5062 34.1H58.9C60.6147 34.1 62 32.7147 62 31V27.9C62 24.4803 59.2197 21.7 55.8 21.7ZM31 21.7C36.9966 21.7 41.85 16.8466 41.85 10.85C41.85 4.85344 36.9966 0 31 0C25.0034 0 20.15 4.85344 20.15 10.85C20.15 16.8466 25.0034 21.7 31 21.7ZM38.44 24.8H37.6359C35.6209 25.7687 33.3831 26.35 31 26.35C28.6169 26.35 26.3888 25.7687 24.3641 24.8H23.56C17.3987 24.8 12.4 29.7987 12.4 35.96V38.75C12.4 41.3172 14.4828 43.4 17.05 43.4H44.95C47.5172 43.4 49.6 41.3172 49.6 38.75V35.96C49.6 29.7987 44.6012 24.8 38.44 24.8ZM16.7691 23.5019C15.6453 22.3878 14.105 21.7 12.4 21.7H6.2C2.78031 21.7 0 24.4803 0 27.9V31C0 32.7147 1.38531 34.1 3.1 34.1H9.48406C10.0944 29.5081 12.865 25.6428 16.7691 23.5019Z"
                  fill="#e1485c"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-12">
          <div className="dashboard-card">
            <div className="dashboard-card-left">
              <h6 onClick={() => navigate("/admin/host2table")}>Total Host</h6>
              <h4>{dashboardCount ? dashboardCount?.totalHost : "0"}</h4>
            </div>

            <div>
              <svg
                width="42"
                height="58"
                viewBox="0 0 42 58"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M35 56.373V58H42V49H36.7C37.0596 50.2728 37.0923 51.6157 36.7952 52.9044C36.498 54.1932 35.8806 55.3862 35 56.373Z"
                  fill="#E1485C"
                />
                <path
                  d="M36.827 35.046L34.537 34.18L32.578 35C28.881 36.3971 24.9514 37.0759 21 37C17.0469 37.0761 13.1157 36.3973 9.417 35L7.458 34.184L5.173 35.048C3.65308 35.6221 2.34401 36.6455 1.41993 37.9818C0.495852 39.3182 0.000579671 40.9042 0 42.529V47H6.105C6.36108 46.518 6.66066 46.0604 7 45.633V43H9V46C8.99993 46.2471 8.90838 46.4854 8.743 46.669C8.46398 46.9946 8.21528 47.3451 8 47.716V50H19V48H17V44H18.779C18.3884 43.5698 18.1311 43.0355 18.0384 42.4619C17.9457 41.8883 18.0216 41.3001 18.2568 40.7688C18.492 40.2375 18.8765 39.7859 19.3634 39.469C19.8504 39.152 20.419 38.9833 21 38.9833C21.581 38.9833 22.1496 39.152 22.6366 39.469C23.1235 39.7859 23.508 40.2375 23.7432 40.7688C23.9784 41.3001 24.0543 41.8883 23.9616 42.4619C23.8689 43.0355 23.6116 43.5698 23.221 44H25V48H23V50.1C24.1286 50.3321 25.1427 50.9462 25.8713 51.8387C26.5999 52.7313 26.9985 53.8478 27 55C26.9965 56.0855 26.6359 57.1397 25.974 58H33V56C33.0001 55.7529 33.0916 55.5146 33.257 55.331C37.11 51.049 33.416 46.846 33.257 46.669C33.0916 46.4854 33.0001 46.2471 33 46V43H35V45.633C35.3404 46.0608 35.6416 46.5183 35.9 47H42V42.529C41.9998 40.9039 41.5047 39.3174 40.5806 37.9806C39.6565 36.6439 38.3472 35.6203 36.827 35.046Z"
                  fill="#E1485C"
                />
                <path
                  d="M22 52H6V49H0V54C0 55.0609 0.421427 56.0783 1.17157 56.8284C1.92172 57.5786 2.93913 58 4 58H22C22.7956 58 23.5587 57.6839 24.1213 57.1213C24.6839 56.5587 25 55.7957 25 55C25 54.2044 24.6839 53.4413 24.1213 52.8787C23.5587 52.3161 22.7956 52 22 52ZM21 23.006C22.313 23.0194 23.5918 22.5868 24.627 21.779L23.373 20.221C22.6844 20.725 21.8533 20.9966 21 20.9966C20.1467 20.9966 19.3156 20.725 18.627 20.221L17.373 21.779C18.4082 22.5868 19.687 23.0194 21 23.006Z"
                  fill="#E1485C"
                />
                <path
                  d="M16 16C17.1046 16 18 15.3284 18 14.5C18 13.6716 17.1046 13 16 13C14.8954 13 14 13.6716 14 14.5C14 15.3284 14.8954 16 16 16Z"
                  fill="#E1485C"
                />
                <path
                  d="M26 16C27.1046 16 28 15.3284 28 14.5C28 13.6716 27.1046 13 26 13C24.8954 13 24 13.6716 24 14.5C24 15.3284 24.8954 16 26 16Z"
                  fill="#E1485C"
                />
                <path
                  d="M14.0002 28.76V24.66C14.4372 25.0772 14.9016 25.4647 15.3902 25.82C15.5902 25.97 15.7902 26.11 16.0002 26.24V28.191C16.0002 29.0136 15.7464 29.8162 15.2737 30.4894C14.8009 31.1627 14.132 31.6737 13.3582 31.953L10.1862 33.153C13.6416 34.4474 17.3112 35.0742 21.0002 35C24.6879 35.0734 28.3559 34.4456 31.8092 33.15L28.6422 31.95C27.869 31.6709 27.2004 31.1603 26.7277 30.4877C26.255 29.8151 26.001 29.0131 26.0002 28.191V26.24C26.2102 26.11 26.4202 25.96 26.6302 25.8C27.1122 25.4515 27.5699 25.0706 28.0002 24.66V28.76C33.8402 26.42 36.3602 21.01 35.0002 15C33.0502 6.43 30.0002 0 21.0002 0C12.0002 0 8.95025 6.43 7.00025 15C5.64025 21.01 8.16025 26.42 14.0002 28.76ZM21.0002 33C19.3369 33.0026 17.6757 32.8816 16.0302 32.638C16.6495 32.0743 17.1445 31.3877 17.4837 30.622C17.8229 29.8564 17.9988 29.0284 18.0002 28.191V27.318C18.9459 27.7397 19.9652 27.9715 21.0002 28C22.0353 27.9715 23.0546 27.7397 24.0002 27.318V28.191C24.0016 29.0285 24.1779 29.8565 24.5179 30.622C24.8578 31.3874 25.3538 32.0734 25.9742 32.636C24.3276 32.8807 22.665 33.0024 21.0002 33ZM12.0002 10.89C19.0802 9.99 23.1002 7.15 24.8002 5.62C26.1267 7.6585 27.9063 9.36285 30.0002 10.6V16.2C30.0002 21.42 24.3902 26 21.0002 26C17.6102 26 12.0002 21.42 12.0002 16.2V10.89Z"
                  fill="#E1485C"
                />
              </svg>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6 col-12">
          <div className="dashboard-card">
            <div className="dashboard-card-left">
              <h6 onClick={() => navigate("/admin/complaintable")}>
                Total Complaints
              </h6>
              <h4>{dashboardCount ? dashboardCount?.totalComplaint : "0"}</h4>
            </div>

            <div>
              <svg
                width="56"
                height="54"
                viewBox="0 0 56 54"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M54.5172 7.88569L48.1161 1.48456C47.6456 1.01391 47.0871 0.640558 46.4723 0.385833C45.8576 0.131107 45.1987 0 44.5332 0C43.8678 0 43.2088 0.131107 42.5941 0.385833C41.9793 0.640558 41.4208 1.01391 40.9503 1.48456L34.5471 7.88569C34.0764 8.35612 33.7031 8.91467 33.4483 9.52943C33.1936 10.1442 33.0625 10.8031 33.0625 11.4686C33.0625 12.134 33.1936 12.7929 33.4483 13.4077C33.7031 14.0224 34.0764 14.581 34.5471 15.0514L40.9482 21.4526C41.4186 21.9232 41.9772 22.2965 42.5919 22.5513C43.2067 22.806 43.8656 22.9371 44.5311 22.9371C45.1965 22.9371 45.8554 22.806 46.4702 22.5513C47.0849 22.2965 47.6435 21.9232 48.1139 21.4526L54.5151 15.0514C55.4653 14.1013 55.9994 12.8128 55.9998 11.469C56.0002 10.1252 55.4669 8.83634 54.5172 7.88569ZM46.4706 16.5182C46.4706 17.0323 46.2663 17.5253 45.9028 17.8888C45.5393 18.2524 45.0462 18.4566 44.5321 18.4566C44.018 18.4566 43.525 18.2524 43.1615 17.8888C42.7979 17.5253 42.5937 17.0323 42.5937 16.5182V16.1305C42.5937 15.6164 42.7979 15.1233 43.1615 14.7598C43.525 14.3963 44.018 14.192 44.5321 14.192C45.0462 14.192 45.5393 14.3963 45.9028 14.7598C46.2663 15.1233 46.4706 15.6164 46.4706 16.1305V16.5182ZM46.4706 11.013C46.4706 11.5271 46.2663 12.0202 45.9028 12.3837C45.5393 12.7472 45.0462 12.9515 44.5321 12.9515C44.018 12.9515 43.525 12.7472 43.1615 12.3837C42.7979 12.0202 42.5937 11.5271 42.5937 11.013V6.41894C42.5937 5.90484 42.7979 5.41179 43.1615 5.04826C43.525 4.68474 44.018 4.48051 44.5321 4.48051C45.0462 4.48051 45.5393 4.68474 45.9028 5.04826C46.2663 5.41179 46.4706 5.90484 46.4706 6.41894V11.013Z"
                  fill="#E1485C"
                />
                <path
                  d="M44.5322 26.1693C43.4418 26.1721 42.3616 25.9585 41.3542 25.5412C40.3468 25.1238 39.4321 24.5108 38.6631 23.7377L32.262 17.3344C31.3072 16.3852 30.5998 15.2164 30.2018 13.9303C29.8038 12.6442 29.7272 11.2801 29.9789 9.95758H6.74144C4.9508 9.96214 3.23515 10.6771 1.97119 11.9455C0.707235 13.2139 -0.0017146 14.9321 3.11413e-06 16.7227V40.5439C3.11413e-06 44.2484 3.03688 47.2853 6.74144 47.2853H16.9505L22.2704 52.6698C23.1966 53.6175 24.7689 53.6175 25.695 52.6698L31.0149 47.2853H41.2024C44.9501 47.2853 47.9654 44.27 47.9654 40.5439V25.4026C46.8898 25.9031 45.7186 26.1647 44.5322 26.1693ZM13.653 31.4849C12.9001 31.4849 12.1781 31.1859 11.6457 30.6535C11.1134 30.1211 10.8143 29.3991 10.8143 28.6462C10.8143 27.8933 11.1134 27.1713 11.6457 26.6389C12.1781 26.1066 12.9001 25.8075 13.653 25.8075C14.4059 25.8075 15.1279 26.1066 15.6603 26.6389C16.1927 27.1713 16.4917 27.8933 16.4917 28.6462C16.4917 29.3991 16.1927 30.1211 15.6603 30.6535C15.1279 31.1859 14.4059 31.4849 13.653 31.4849ZM23.9719 31.4849C23.2191 31.4849 22.497 31.1859 21.9647 30.6535C21.4323 30.1211 21.1332 29.3991 21.1332 28.6462C21.1332 27.8933 21.4323 27.1713 21.9647 26.6389C22.497 26.1066 23.2191 25.8075 23.9719 25.8075C24.7248 25.8075 25.4469 26.1066 25.9792 26.6389C26.5116 27.1713 26.8107 27.8933 26.8107 28.6462C26.8107 29.3991 26.5116 30.1211 25.9792 30.6535C25.4469 31.1859 24.7248 31.4849 23.9719 31.4849ZM34.2887 31.4849C33.5358 31.4849 32.8138 31.1859 32.2814 30.6535C31.7491 30.1211 31.45 29.3991 31.45 28.6462C31.45 27.8933 31.7491 27.1713 32.2814 26.6389C32.8138 26.1066 33.5358 25.8075 34.2887 25.8075C35.0416 25.8075 35.7636 26.1066 36.296 26.6389C36.8283 27.1713 37.1274 27.8933 37.1274 28.6462C37.1274 29.3991 36.8283 30.1211 36.296 30.6535C35.7636 31.1859 35.0416 31.4849 34.2887 31.4849Z"
                  fill="#E1485C"
                />
              </svg>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6 col-12">
          <div className="dashboard-card">
            <div className="dashboard-card-left">
              <h6 onClick={() => navigate("/admin/vipplantable")}>
                Total Vip Plan
              </h6>
              <h4>{dashboardCount ? dashboardCount?.totalVipPlan : "0"}</h4>
            </div>

            <div>
              <svg
                width="58"
                height="52"
                viewBox="0 0 58 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M28.6667 9.33333C31.244 9.33333 33.3333 7.244 33.3333 4.66667C33.3333 2.08934 31.244 0 28.6667 0C26.0893 0 24 2.08934 24 4.66667C24 7.244 26.0893 9.33333 28.6667 9.33333Z"
                  fill="#E1485C"
                />
                <path
                  d="M52.6667 17.3333C55.244 17.3333 57.3333 15.244 57.3333 12.6667C57.3333 10.0893 55.244 8 52.6667 8C50.0893 8 48 10.0893 48 12.6667C48 15.244 50.0893 17.3333 52.6667 17.3333Z"
                  fill="#E1485C"
                />
                <path
                  d="M4.66667 17.3333C7.244 17.3333 9.33333 15.244 9.33333 12.6667C9.33333 10.0893 7.244 8 4.66667 8C2.08934 8 0 10.0893 0 12.6667C0 15.244 2.08934 17.3333 4.66667 17.3333Z"
                  fill="#E1485C"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M53.2932 15.7253C53.3758 15.3124 53.3259 14.8839 53.1507 14.5009C52.9754 14.118 52.6838 13.8001 52.3173 13.5926C51.9508 13.3851 51.5282 13.2986 51.1097 13.3455C50.6912 13.3923 50.2981 13.57 49.9865 13.8533L44.0505 19.2507C43.3255 19.9096 42.4502 20.3809 41.501 20.6236C40.5518 20.8662 39.5577 20.8727 38.6054 20.6425C37.6531 20.4123 36.7717 19.9525 36.0381 19.3031C35.3046 18.6538 34.7412 17.8346 34.3972 16.9173L30.5385 6.632C30.3953 6.25123 30.1394 5.92317 29.8049 5.69161C29.4704 5.46006 29.0733 5.336 28.6665 5.336C28.2597 5.336 27.8625 5.46006 27.5281 5.69161C27.1936 5.92317 26.9377 6.25123 26.7945 6.632L22.9358 16.9173C22.5929 17.8355 22.03 18.6556 21.2965 19.3058C20.563 19.9559 19.6813 20.4163 18.7286 20.6466C17.7758 20.8768 16.7812 20.87 15.8317 20.6267C14.8823 20.3833 14.007 19.9108 13.2825 19.2507L7.34649 13.8533C7.03491 13.57 6.64183 13.3923 6.2233 13.3455C5.80477 13.2986 5.38215 13.3851 5.01566 13.5926C4.64918 13.8001 4.35754 14.118 4.18231 14.5009C4.00709 14.8839 3.95722 15.3124 4.03982 15.7253L8.51449 38.104C8.84683 39.7667 9.74507 41.2629 11.0563 42.3379C12.3676 43.4128 14.0109 44.0002 15.7065 44H41.6265C43.3221 44.0002 44.9654 43.4128 46.2766 42.3379C47.5879 41.2629 48.4862 39.7667 48.8185 38.104L53.2932 15.7253ZM44.6665 48H12.6665C12.1361 48 11.6274 48.2107 11.2523 48.5858C10.8772 48.9609 10.6665 49.4696 10.6665 50C10.6665 50.5304 10.8772 51.0391 11.2523 51.4142C11.6274 51.7893 12.1361 52 12.6665 52H44.6665C45.1969 52 45.7056 51.7893 46.0807 51.4142C46.4558 51.0391 46.6665 50.5304 46.6665 50C46.6665 49.4696 46.4558 48.9609 46.0807 48.5858C45.7056 48.2107 45.1969 48 44.6665 48Z"
                  fill="#E1485C"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-md-6 col-12 p-2">
          <div className="dashboard-userdata p-4">
            <h4>New User's</h4>
            <div className="d-flex justify-content-between align-items-center">
              <h6>
                {" "}
                <b>Total User : </b> {totalUser}
              </h6>
              <DateRange
                setEndDate={setEndDate}
                setStartDate={setStartDate}
                startDate={startDate}
                endDate={endDate}
              />
            </div>
            <div className="mt-3">
              <ApexUserChartData
                startDate={startDateData}
                endDate={endDateData}
              />
            </div>
          </div>
        </div>

        <div className="col-md-6 col-12 p-2">
          <div className="dashboard-userdata p-4">
            <h4>Revenue</h4>
            <div className="d-flex justify-content-between align-items-center">
              <h6>
                {" "}
                <b>Total Revenue :</b>
                {totalRevenue}($)
              </h6>
              <DateRange
                setEndDate={setEndDate1}
                setStartDate={setStartDate1}
                startDate={startDate1}
                endDate={endDate1}
              />
            </div>
            <div className="mt-3">
              <ApexRevenueChartData
                startDate={startDateData1}
                endDate={endDateData1}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const ApexUserChartData = ({ startDate, endDate }) => {
  const dispatch = useDispatch();
  const { dashUserData } = useSelector((state) => state.admin);

  useEffect(() => {
    const payload = { startDate: startDate, endDate: endDate, type: "user" };

    if (startDate && endDate && payload?.type === "user") {
      dispatch(dashboardAnalyticUserData(payload));
    }
  }, [dispatch, startDate, endDate]);

  let label = [];
  let dataAmount = [];
  let data = [];

  if (dashUserData?.length > 0) {
    dashUserData.map((data_) => {
      const newDate = data_._id;

      var date;
      if (newDate._id) {
        data = dayjs(newDate?._id).format("DD MMM YYYY");
      } else {
        date = dayjs(newDate).format("DD MMM YYYY");
      }

      date?.length > 0 && label.push(date);

      dataAmount.push(data_.count);
    });
  }

  const totalSeries = {
    labels: label,
    dataSet: [
      {
        name: "Total User",
        data: dataAmount,
        color: "#e1485c",
      },
    ],
  };

  const optionsTotal = {
    chart: {
      type: "area",
      stacked: true,
      height: 500,
      background: "#fff",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    markers: {
      size: 0,
    },
    fill: {
      type: "gradient",
      gradient: {
        inverseColors: false,
        shade: "light",
        type: "vertical",
        opacityFrom: 0.9,
        opacityTo: 0.6,
        stops: [0, 100, 100, 100],
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#8e8da4",
        },
        offsetX: 10,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    xaxis: {
      categories: label,
      rotate: 0,
      rotateAlways: true,
      minHeight: 200,
      maxHeight: 280,
      offsetX: 2,
    },
    tooltip: {
      shared: true,
    },
    title: {
      text: "User Data",
      style: {
        color: "#1C2B20",
        marginTop: "50px",
      },
      align: "center",
      offsetX: 20,
      cssClass: "mt-5",
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      offsetY: -10,
      offsetX: -100,
    },
    colors: ["#259ACD", "#0f7085"],
  };

  return (
    <div id="chart">
      <ApexCharts
        options={optionsTotal}
        series={totalSeries.dataSet}
        type="area"
        height={400}
      />
    </div>
  );
};

const ApexRevenueChartData = ({ startDate, endDate }) => {
  const dispatch = useDispatch();
  const { dashRevnueData } = useSelector((state) => state.admin);

  useEffect(() => {
    const payload = { startDate: startDate, endDate: endDate, type: "revenue" };

    if (startDate && endDate && payload?.type === "revenue") {
      dispatch(dashboardAnalyticRevnueData(payload));
    }
  }, [dispatch, startDate, endDate]);

  let revenueData = [];
  let data = [];
  let label = [];

  if (dashRevnueData?.length > 0) {
    dashRevnueData.map((data_) => {
      const newDate = data_._id;

      var date;
      if (newDate._id) {
        data = dayjs(newDate?._id).format("DD MMM YYYY");
      } else {
        date = dayjs(newDate).format("DD MMM YYYY");
      }

      date?.length > 0 && label.push(date);

      revenueData.push(data_.dollar);
    });
  }

  const totalSeries = {
    dataSet: [
      {
        name: "Total Revenue ($)",
        data: revenueData,
        color: "#e1485c",
      },
    ],
  };

  const optionsTotal = {
    chart: {
      type: "area",
      stacked: true,
      height: 500,
      background: "#fff",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    markers: {
      size: 0,
    },
    fill: {
      type: "gradient",
      gradient: {
        inverseColors: false,
        shade: "light",
        type: "vertical",
        opacityFrom: 0.9,
        opacityTo: 0.6,
        stops: [0, 100, 100, 100],
        colors: ["#FF0000", "#008000"],
      },
    },
    yaxis: {
      tickAmount: 5,
      labels: {
        style: {
          colors: "#e1485c",
        },
        offsetX: 10,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    xaxis: {
      categories: label,
      rotate: 0,
      rotateAlways: true,
      minHeight: 200,
      maxHeight: 280,
      offsetX: 2,
    },
    tooltip: {
      shared: true,
    },
    title: {
      text: "New Revenue Data",
      style: {
        color: "#1C2B20",
        marginTop: "50px",
      },
      align: "center",
      offsetX: 20,
      cssClass: "mt-5",
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      offsetY: -10,
      offsetX: -100,
    },
  };

  return (
    <div id="chart">
      <ApexCharts
        options={optionsTotal}
        series={totalSeries.dataSet}
        type="area"
        height={400}
      />
    </div>
  );
};

export default connect(null, {
  getAdmin,
  getDashboardCount,
})(Dashboard);
