import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance, apiInstanceFetch } from "../../Component/Api/axiosApi";
import { setToast } from "../../Component/Api/toastServices";

const initialState = {
  pendingComplain: [],
  complainInfo : [],
  isLoading: false,
  isSkeleton: false,
  total: null,
};

export const getPendingComplain = createAsyncThunk(
  "admin/complaint/complaintsForAdmin",
  async (payload) => {
    return apiInstanceFetch.get(
      `admin/complaint/complaintsForAdmin?type=${payload}`
    );
  }
);

export const getComplainInfo = createAsyncThunk(
  "admin/complaint/complaintInfo",
  async (payload) => {
    return apiInstanceFetch.get(
      `admin/complaint/complaintInfo?complaintId=${payload}`
    );
  }
);

export const complainSolved = createAsyncThunk(
  "admin/complaint/solveComplaint",
  async (payload) => {
    return apiInstance.patch(
      `admin/complaint/solveComplaint?complaintId=${payload}`
    );
  }
);

const complainSlice = createSlice({
  name: "complainSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPendingComplain.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getPendingComplain.fulfilled, (state, action) => {
      state.isSkeleton = false;
      state.pendingComplain = action?.payload?.complaint;
      state.total = action?.payload?.totalVideoCallVideo;
    });

    builder.addCase(getPendingComplain.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(getComplainInfo.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getComplainInfo.fulfilled, (state, action) => {
      state.isSkeleton = false;
      state.complainInfo = action?.payload?.complaint;
    });

    builder.addCase(getComplainInfo.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(complainSolved.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(complainSolved.fulfilled, (state, action) => {
      state.isSkeleton = false;
      if (action?.payload?.status) {
        state.pendingComplain = state.pendingComplain.filter(
          (item) => item?._id !== action?.payload?.complaint?._id
        );
        setToast("success", "Complain Solved Succesfully");
      }
    });

    builder.addCase(complainSolved.rejected, (state, action) => {
      state.isSkeleton = false;
    });
  },
});
export default complainSlice.reducer;
