import React, { useEffect, useState } from "react";
import Pagination from "../../extra/Pagination";
import Table from "../../extra/Table";
import ButtonCustom from "../../extra/Button";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import Searching from "../../extra/Searching";
import { useNavigate } from "react-router-dom";
import { getGiftAll, deleteGift } from "../../../store/slice/GiftSlice";
import { connect, useDispatch, useSelector } from "react-redux";
import { openDialog } from "../../../store/slice/dialogueSlice";
import SVGA from "svgaplayerweb";
import { permissionError, warning } from "../../utils/Alert";
import dayjs from "dayjs";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import styled from "@emotion/styled";
import { Button, Menu, MenuItem, alpha } from "@mui/material";
import AddSvgaGiftDialog from "../../dialogComponent/AddSvgaGiftDialog";
import GiftImageDialog from "../../dialogComponent/GiftImageDialog";
import { isSkeleton } from "../../utils/allSelector";
import { baseURL } from "../../utils/config";

const SkeletonLoaderGift = () => (
  <div className="giftCategoryWiseLoader col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
    <div className="gift-Image">
      <div className="giftImageShow">
        <p></p>
      </div>
      <div className="giftText">
        <h5></h5>
        <h4></h4>
        <div className="gift-button">
          <ButtonCustom newClass={"edit-btn"} />
          <ButtonCustom newClass={"delete-btn"} />
        </div>
      </div>
    </div>
  </div>
);
function GiftPage(props) {
  const { allGift, totalGift } = useSelector((state) => state.gift);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);
  const isSkeletonLoader = useSelector(isSkeleton);
  const [data, setData] = useState([]);
  const [start, setStart] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [giftFound, setGiftFound] = React.useState(false);
  
  const hasPermission = useSelector(
    (state) => state.admin.admin.flag
  );

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const payload = {
      start: start,
      limit: limit,
    };
    props.getGiftAll(payload);
  }, [start, limit]);

  useEffect(() => {
    setData(allGift);
    const allGiftFilter = allGift?.map((item)=>item?.gift?.length)
    const checkValue=allGiftFilter?.every(data=>data === 0)
    setGiftFound(checkValue);
  }, [allGift]);

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handleDeleteGift = (item, giftData) => {
    if (!hasPermission) return permissionError();

    const data = warning();
    data
      .then((res) => {
        if (res) {
          if (res) {
            const payload = {
              id: giftData?._id,
              categoryId: item?._id,
            };
            props.deleteGift(payload);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const handleNewOpenDialog = (type) => {
    if (!hasPermission) return permissionError();

    setAnchorEl(null);
    const payload = {
      dialogue: true,
      dialogueType: type,
      dialogueData: null,
    };
    dispatch(openDialog(payload));
  };

  useEffect(() => {
    data?.map((item) =>
      item?.gift?.map((gift, index) => {
        if (gift?.image?.split(".")?.pop() === "svga") {
          var player = new SVGA.Player(`div[attr="${index}"]`);
          var parser = new SVGA.Parser(`div[attr="${index}"]`);
          parser.load(gift.image, function (videoItem) {
            player.setVideoItem(videoItem);
            player.startAnimation();
          });
        }
      })
    );
  }, [data]);

  const handleEditGift = (item, giftData) => {
    if (!hasPermission) return permissionError();

    const giftSend = {
      giftAll: item,
      giftData: giftData,
    };
    const payload = {
      dialogue: true,
      dialogueType: giftData?.type === 3 ? "svgaNew" : "imageGif",
      dialogueData: giftSend,
    };

    dispatch(openDialog(payload));
  };




  return (
    <div className="game-table">
      {dialogueType === "svgaNew" && <AddSvgaGiftDialog />}
      {dialogueType === "imageGif" && <GiftImageDialog />}
      <div className="row pt-1">
        <div className="col-8"></div>
        <div className="col-4 new-button d-flex justify-content-end">
          {/* <ButtonCustom
            btnName={"New"}
            btnIcon={<AddRoundedIcon />}
            type={"button"}
            
            newClass={"new-btn"}
          /> */}
          <Button
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
          >
            New
          </Button>
          <Menu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => handleNewOpenDialog("svgaNew")}
              disableRipple
            >
              SVGA
            </MenuItem>
            <MenuItem
              onClick={() => handleNewOpenDialog("imageGif")}
              disableRipple
            >
              Image,GIF
            </MenuItem>
          </Menu>
        </div>
      </div>
      <div className="gift-show">
        {isSkeletonLoader === true ? (
          <div className="row">
            <SkeletonLoaderGift />
            <SkeletonLoaderGift />
            <SkeletonLoaderGift />
            <SkeletonLoaderGift />
            <SkeletonLoaderGift />
            <SkeletonLoaderGift />
            <SkeletonLoaderGift />
            <SkeletonLoaderGift />
          </div>
        ) : giftFound === false ? (
          data?.map((item, index) => {
            return (
              <div className="giftCategoryWise">
                {item?.gift?.length > 0 ? <h5>{item?.name}</h5> : ""}
                <div className="giftBox row">
                  {item?.gift?.length > 0 &&
                    item?.gift?.map((giftData, i) => {
                      return (
                        <>
                          <div className=" col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                            <div className="gift-Image">
                              <div className="giftImageShow">
                                {giftData?.type === 3 ? (
                                  <div
                                    id="svga"
                                    attr={i}
                                    style={{
                                      boxShadow:
                                        "0 5px 15px 0 rgb(105 103 103 / 00%)",
                                      marginTop: 10,
                                      float: "left",
                                      objectFit: "contain",
                                      marginRight: 15,
                                      height: "80px",
                                      width: "80px",
                                    }}
                                  ></div>
                                ) : (
                                  <img src={baseURL +  giftData?.image} />
                                )}
                              </div>
                              <div className="giftText">
                             
                                <div className="gift-button">
                                  <ButtonCustom
                                    btnIcon={<EditRoundedIcon />}
                                    newClass={"edit-btn"}
                                    onClick={() =>
                                      handleEditGift(item, giftData)
                                    }
                                  />
                                  <ButtonCustom
                                    btnIcon={<DeleteRoundedIcon />}
                                    newClass={"delete-btn"}
                                    onClick={() =>
                                      handleDeleteGift(item, giftData)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
            );
          })
        ) : (
          <h2
            style={{ height: "600px", borderBottom: "none" }}
            className="not-foundText"
          >
            Gift Not Found....
          </h2>
        )}
      </div>
    </div>
  );
}
export default connect(null, { getGiftAll, deleteGift })(GiftPage);
