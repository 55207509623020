import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Dashboard from "./Dashboard";
import Navbar from "../navbar/Navbar";
import EditApp from "./EditApp";
import Profile from "../Page/Profile";
import ShortVideo from "../Table/ShortVideo";
import LiveStreamVideo from "../Table/LiveStreamVideo";
import VideoCallVideo from "../Table/VideoCallVideo";
import PostTable from "../Table/PostTable";
import GiftMain from "./GiftPage/GiftMain";
import HostTableTwo from "../Table/HostTableTwo";
import VipPlanTable from "./VipPlanTable";
import ComplainMain from "./Complain/ComplainMain";
import  UserTable  from "./User/UserTable";
import PurchasePlanTable from "../Table/PurchasePlanTable";

export default function Admin() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      location.pathname == "/" ||
      location.pathname == "/admin" ||
      location.pathname == "/admin/" ||
      location.pathname == ""
    ) {
      navigate("/admin/admindashboard");
    }
  }, []);

  return (
    <div className="pagesContent">
      <Navbar />
      <div className="mainPage">
        <div className="mainPage-bg"></div>
        <Routes>
          <Route path="/admindashboard" element={<Dashboard />} />
          <Route path="/updateApp" element={<EditApp />} />
          <Route path="/usertable" element={<UserTable />} />
          

          <Route path="/shortVideostable" element={<ShortVideo />} />
          <Route path="/liveStreamVideotable" element={<LiveStreamVideo />} />
          <Route path="/videoCallVideotable" element={<VideoCallVideo />} />
          <Route path="/host2table" element={<HostTableTwo />} />
          <Route path="/gifttable" element={<GiftMain />} />
          <Route path="/posttable" element={<PostTable />} />
          <Route path="/vipplantable" element={<VipPlanTable />} />
          <Route path="/purchaseplantable" element={<PurchasePlanTable />} />

          <Route path="/complaintable" element={<ComplainMain />} />
          <Route path="/settingdata" element={<EditApp />} />
          <Route path="/profile" element={<Profile />} />
        </Routes>
      </div>
    </div>
  );
}
