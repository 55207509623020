import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../Component/Api/axiosApi";
import { apiInstanceFetch } from "../../Component/Api/axiosApi";
import { SetDevKey, setToken } from "../../Component/utils/setAuth";
import { secretKey } from "../../Component/utils/config";
import { setToast } from "../../Component/Api/toastServices";
import { jwtDecode } from "jwt-decode";

const initialState = {
  admin: {},
  dashboardCount: null,
  totalLiveApp:null,
  isAuth: false,
  isLoading: false,
  permission : false,
  dashUserData:[],
  dashRevnueData : []
};

export const login = createAsyncThunk("admin/login", async (payload) => {
  return apiInstanceFetch.post("admin/admin/login", payload);
});

export const signUp = createAsyncThunk("admin/admin/signUp", async (payload) => {
  return apiInstanceFetch.post("admin/admin/signUp", payload);
});


export const getAdmin = createAsyncThunk(
  `admin/getProfile`,
  async (payload) => {
    return apiInstanceFetch.get(`admin/admin/profile?adminId=${payload}`);
  }
);

export const getDashboardCount = createAsyncThunk(
  `admin/dashboard/dashBoardCount`,
  async (payload) => {
    return apiInstanceFetch.get(`admin/dashboard/dashBoardCount` , payload);
  }
);



export const updateProfile = createAsyncThunk(
  "admin/admin/updateProfile",
  async (payload) => {
    return apiInstance.patch(
      `admin/admin/updateProfile?adminId=${payload?.id}`,
      payload?.data
    );
  }
);

export const updatePassword = createAsyncThunk(
  "admin/admin/updatePassword",
  async (payload) => {
    return apiInstance.patch(`admin/admin/updatePassword`, payload?.data);
  }
);

export const dashboardAnalyticUserData = createAsyncThunk(
  "admin/dashboard/dashboarduserAnalytic",
  async (payload) => {
    return apiInstanceFetch.get(
      `admin/dashboard/chartAnalytic?startDate=${payload?.startDate}&endDate=${payload?.endDate}&type=user`
    );
  }
);

export const dashboardAnalyticRevnueData = createAsyncThunk(
  "admin/dashboard/dashboardrevenueAnalytic",
  async (payload) => {
    return apiInstanceFetch.get(
      `admin/dashboard/chartAnalytic?startDate=${payload?.startDate}&endDate=${payload?.endDate}&type=revenue`
    );
  }
);
const authSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setOldAdmin(state, action) {
      let token_ = JSON.parse(action.payload);
      state.admin = token_;
      state.isAuth = true;
      SetDevKey(secretKey);
    },

    logout(state, action) {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("key");
      sessionStorage.removeItem("isAuth");
      sessionStorage.removeItem("adminToken");
      state.admin = {};
      state.isAuth = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      if (action.payload && action.payload.status !== false) {
        let token_ = action.payload.token && jwtDecode(action.payload.token);
        console.log('token_', token_)
        state.admin = token_;
        state.isAuth = true;
        state.permission = token_?.flag;
        state.isLoading = false;
        SetDevKey(secretKey);
        setToken(token_ ? JSON.stringify(token_) : undefined);
        sessionStorage.setItem(
          "adminToken",
          JSON.stringify(action.payload.token)
        );
        sessionStorage.setItem(
          "token",
          token_ ? JSON.stringify(token_) : undefined
        );
        sessionStorage.setItem("key", secretKey ? secretKey : undefined);
        sessionStorage.setItem("isAuth", true);
        sessionStorage.setItem("user", "admin");
        setToast("success" , "Login Succesfully")
        window.location.href = "/admin/admindashboard";
      } else {
        setToast("error", action.payload?.message);
        state.isLoading = false;
      }
    });
    builder.addCase(login.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(signUp.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(signUp.fulfilled, (state, action) => {
      state.isLoading = false;
      if(action?.payload?.status === true){
        setToast("success" , "SignUp Succesfully")
        setTimeout(() => {
          window.location.href = "/";
        }, 1000);
      } else {
        setToast("error" , action?.payload?.message)
      }
    });
    builder.addCase(signUp.rejected, (state, action) => {
      state.isLoading = false;
    });
    // admin api
    builder.addCase(dashboardAnalyticUserData.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(dashboardAnalyticUserData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.dashUserData = action.payload.chartAnalyticOfUsers;
    });
    builder.addCase(dashboardAnalyticUserData.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(dashboardAnalyticRevnueData.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(dashboardAnalyticRevnueData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.dashRevnueData = action.payload.chartAnalyticOfRevenue;
    });
    builder.addCase(dashboardAnalyticRevnueData.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getAdmin.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(getAdmin.fulfilled, (state, action) => {
      state.isLoading = false;
      state.admin = action.payload.user;
    });
    builder.addCase(getAdmin.rejected, (state, action) => {
      state.isLoading = false;
    });

    // dashboard count
    builder.addCase(getDashboardCount.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(getDashboardCount.fulfilled, (state, action) => {
      state.isLoading = false;
      state.dashboardCount = action.payload;
      state.totalLiveApp = action.payload;
    });
    builder.addCase(getDashboardCount.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updateProfile.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(updateProfile.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload?.status === true) {
        state.admin = action.payload.admin;
        setToast("success", "Updated profile Successfully");
      } else {
        setToast("error", action.payload  ?.message);
      }
    });
    builder.addCase(updateProfile.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(updatePassword.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updatePassword.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload?.status === true) {
        state.admin = action.payload.admin;
        setToast("success", "Admin Password Update Successfully");
        setTimeout(() => {
          window.location.href = "/";
        }, 1000);
      } else {
        setToast("error", action.payload?.message);
      }
    });
    builder.addCase(updatePassword.rejected, (state, action) => {
      state.isLoading = false;
    });
   
  },
});
export default authSlice.reducer;
export const { setOldAdmin, logout } = authSlice.actions;
