import React from "react";
import EdiText from "react-editext";
import ToggleSwitch from "./ToggleSwitch";
import {
  updateOtherSetting,
  updateSettingInput,
} from "../../store/slice/appSlice";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

const OtherSettingInput = (props) => {
  let {
    label,
    valueData,
    onChangeSwitch,
    switchValue,
    onClickSwitch,
    settingId,
    labelValue,
  } = props;
  const location = useLocation();

  const monetizationId = localStorage?.getItem("monetizationId");

  const handleOnSubmit = (val, type) => {
    if (valueData?.typeAdd === "P") {
      let payload;
      if (type === "editText") {
        const typeData = valueData.typeText;
        payload = {
          appId: location?.state?.editAppData?._id,
          data: { [typeData]: val ? val : "null" },
          settingId: settingId,
          type: valueData?.typeAdd,
          showType: typeData,
        };
      } else {
        const typeData = valueData.typeValue;

        payload = {
          appId: location?.state?.editAppData?._id,
          data: { [typeData]: val ? val : "null" },
          settingId: settingId,
          type: valueData?.typeAdd,
          showType: typeData,
        };
      }
      props.updateSettingInput(payload);
    } else {
      let payload;
      if (type === "editText") {
        const typeData = valueData.typeText;

        payload = {
          id: monetizationId,
          data: { [typeData]: val ? val : "null" },
          type: valueData?.typeAdd,
          showType: typeData,
        };
      } else {
        const typeData = valueData.typeValue;

        payload = {
          id: monetizationId,
          data: { [typeData]: val ? val : "null" },
          type: valueData?.typeAdd,
          showType: typeData,
        };
      }
      props.updateOtherSetting(payload);
    }
  };

  return (
    <div className="other-setting-input">
      <label>{label}</label>
      <div className="input-box">
        <div className="row gx-3">
          <div className="col-12 mb-2 col-sm-4 col-md-12 col-lg-4 mb-lg-0 mb-sm-0 mb-md-2">
            {valueData?.text == label ? (
              <div className="editTextDark">
                <EdiText
                  type="text"
                  value={valueData?.text === "null" ? "" : valueData?.text}
                  name={"name"}
                  placeholder="Name"
                  onSave={(val) => handleOnSubmit(val, "editText")}
                />
              </div>
            ) : (
              <div>
                <EdiText
                  type="text"
                  value={valueData?.text === "null" ? "" : valueData?.text}
                  name={"name"}
                  placeholder="Name"
                  onSave={(val) => handleOnSubmit(val, "editText")}
                />
              </div>
            )}
          </div>
          <div className="col-12 mb-2 col-sm-6 col-md-12 col-lg-6 mb-lg-0 mb-sm-0 mb-md-2">
            {valueData?.title === labelValue ? (
              <div className="editTextDark">
                <EdiText
                  type="text"
                  name={"value"}
                  placeholder="Value"
                  value={valueData?.title === "null" ? "" : valueData?.title}
                  onSave={(val) => handleOnSubmit(val, "editValue")}
                />
              </div>
            ) : (
              <div>
                <EdiText
                  type="text"
                  name={"value"}
                  placeholder="Value"
                  value={valueData?.title === "null" ? "" : valueData?.title}
                  onSave={(val) => handleOnSubmit(val, "editValue")}
                />
              </div>
            )}
          </div>
          <div className="col-12 mb-2 col-sm-2 col-md-12 col-lg-2 mb-lg-0 mb-sm-0 mb-md-2 d-flex align-items-center">
            <ToggleSwitch
              value={switchValue}
              onChange={onChangeSwitch}
              onClick={onClickSwitch}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default connect(null, { updateOtherSetting, updateSettingInput })(
  OtherSettingInput
);
