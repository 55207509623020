import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Searching from "../extra/Searching";
import Table from "../extra/Table";
import Pagination from "../extra/Pagination";
import { useNavigate } from "react-router-dom";
import { getPurchasePlan } from "../../store/slice/PurchasePlanSlice";
import { baseURL } from "../utils/config";
import noImage from "../../assets/images/noImage.jpg";


const PurchasePlanTable = () => {
  const { purchasePlan, total } = useSelector((state) => state.purchasePlan);

  const dispatch = useDispatch();
  const [start, setStart] = useState(1);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState([]);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  useEffect(() => {
    const payload = {
      start,
      limit,
    };
    dispatch(getPurchasePlan(payload));
  }, [dispatch]);

  useEffect(() => {
    setData(purchasePlan);
  }, [purchasePlan]);

  const handleFilterData = (filteredData) => {
    setFilteredData(filteredData);
  };

  const handlePageChange = (pageNumber) => {
    setStart(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setStart(1);
    setLimit(value);
  };

  const handleInfo = (data) => {
    navigate(`/admin/user/particularuser`, { state: { userId: data?._id } });
  };

  const mapData = [
    {
      Header: "Index",
      body: "index",
      Cell: ({ index }) => (
        <span className="  text-nowrap">
          {(start - 1) * limit + parseInt(index) + 1}
        </span>
      ),
    },

    {
      Header: "Image",
      body: "Image",
      Cell: ({ row }) => (
        <div className="d-flex justify-content-center align-items-center">
        <div style={{ width: "100px", marginRight: "10px" }}>
          <img
            src={!(row?.userId?.profileImage?.includes('\\')) ? baseURL +  row?.userId?.profileImage : noImage}
            height={50}
            width={50}
            style={{ objectFit: "cover" }}
            alt="User Profile"
          />
        </div>
      
        <span className="text-nowrap" style={{ width: "150px", textAlign: "left" }}>
          {row?.userId?.firstName ? row?.userId?.firstName : "-"}
        </span>
      </div>
      
      ),
    },

    {
      Header: "Validity",
      body: "validity",
      Cell: ({ row }) => (
        <span className="text-nowrap">
          {row?.vipPlanId?.validity ? row?.vipPlanId?.validity : "-"}
        </span>
      ),
    },

    {
      Header: "validityType",
      body: "validity type",
      Cell: ({ row }) => (
        <span className="text-nowrap">
          {row?.vipPlanId?.validityType ? row?.vipPlanId?.validityType : "-"}
        </span>
      ),
    },

    {
      Header: "PaymentGateway",
      body: "paymentGateway",
      Cell: ({ row }) => (
        <span className="  text-nowrap">{row?.paymentGateway}</span>
      ),
    },

    {
      Header: "Dollar",
      body: "dollar",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {row?.dollar !== "" ? row?.dollar : "-"}
        </span>
      ),
    },

    {
      Header: "Rupee",
      body: "rupee",
      Cell: ({ row }) => (
        <span className="  text-nowrap">{row?.rupee ? row?.rupee : "-"}</span>
      ),
    },
  ];

  return (
    <div className="mainCustomAd hostTable">
      <div className="customAdTable">
        <div className="row">
          <div className="col-12 col-sm-3">
            <h6 style={{ marginTop: "10px", color: "#e1485c" }}>
              Total Revenue: {purchasePlan?.length}
            </h6>
          </div>
        </div>
        <div className="row mt-3 pt-1">
          <div className="col-8">
            <Searching
              placeHolder={"What’re you searching for?"}
              type="client"
              buttonShow={true}
              data={purchasePlan}
              setData={setData}
              onFilterData={handleFilterData}
            />
          </div>
        </div>
        <Table
          data={data}
          type={"server"}
          mapData={mapData}
          start={start}
          limit={limit}
        />
        <Pagination
          type={"server"}
          start={start}
          limit={limit}
          totalData={total}
          setStart={setStart}
          setLimit={setLimit}
          handleRowsPerPage={handleRowsPerPage}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default PurchasePlanTable;
