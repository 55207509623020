/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Title from "../extra/Title";
import Searching from "../extra/Searching";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "../extra/Button";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import Pagination from "../extra/Pagination";
import { openDialog } from "../../store/slice/dialogueSlice";
import VideoCallDialog from "../dialogComponent/VideoCallDialog";
import {
  deleteVideoCallVideo,
  getVideoCallVideo,
} from "../../store/slice/videoCallSlice";
import dayjs from "dayjs";
import { isSkeleton } from "../utils/allSelector";
import { baseURL } from "../utils/config";
import { permissionError, warning } from "../utils/Alert";

const Row = () => (
  <div className="row">
    <div className="skeleton-table"></div>
    <div className="skeleton-table"></div>
    <div className="skeleton-table"></div>
    <div className="skeleton-table"></div>
    <div className="skeleton-table"></div>
  </div>
);
const VideoCallVideo = () => {
  const [data, setData] = useState([]);
  const [tableValues, setTableValues] = useState([]);
  const [start, setStart] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState();
  const { videoCallVideo, total } = useSelector(
    (state) => state.videoCallVideo
  );
  
  const hasPermission = useSelector(
    (state) => state.admin.admin.flag
  );
  const navigate = useNavigate();
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);
  const isSkeletonLoader = useSelector(isSkeleton);
  const [skeleton, setSkeleton] = useState();
  const numberOfRows = 10;

  useEffect(() => {
    const renderedRows = [...Array(numberOfRows)]?.map((e, i) => (
      <div>
        <Row />
      </div>
    ));
    setSkeleton(renderedRows);
  }, [numberOfRows]);

  const dispatch = useDispatch();

  useEffect(() => {
    const payload = {
      start: start,
      limit: limit,
    };
    dispatch(getVideoCallVideo(payload));
  }, [start, limit]);

  useEffect(() => {
    if (videoCallVideo) {
      setData(videoCallVideo);
      setTableValues(videoCallVideo);
    }
  }, [videoCallVideo]);

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handlePageChange = (pageNumber) => {
    setStart(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setStart(1);
    setLimit(value);
  };

  const handleDelete = (id) => {
    if (!hasPermission) return permissionError();

    const data = warning();
    data
      .then((res) => {
        if (res) {
          if (res) {
            dispatch(deleteVideoCallVideo(id));
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const handleNewOpenDialog = (type) => {
    if (!hasPermission) return permissionError();

    const payload = {
      dialogue: true,
      dialogueType: type,
      dialogueData: null,
    };
    dispatch(openDialog(payload));
  };

  return (
    <div className="shortVideo">
      <Title title="Video Call Video" />
      {dialogue && dialogueType === "videoCall" && <VideoCallDialog />}
      <div className="customAdTable">
        <h6 style={{ marginTop: "10px" }}>Total Video Call Videos: {total}</h6>
        <div className="row mt-3 pt-1">
          <div className="col-8">
            <Searching
              placeHolder={"What’re you searching for?"}
              type="client"
              buttonShow={true}
              data={tableValues}
              setData={setData}
              onFilterData={handleFilterData}
              searchValue={search}
            />
          </div>
          <div className="col-4 new-button d-flex justify-content-end align-items-center">
            <Button
              btnName={"New"}
              btnIcon={<AddRoundedIcon />}
              type={"button"}
              onClick={() => handleNewOpenDialog("videoCall")}
              newClass={"new-btn"}
            />
          </div>
        </div>
        <div className="simple-table">
          <table>
            <thead>
              <tr>
                <th>
                  <span>Index</span>
                </th>
                <th>
                  <span>Video</span>
                </th>
                <th>
                  <span>Link</span>
                </th>
                <th>
                  <span>Delete</span>
                </th>
                <th>
                  <span>Created At</span>
                </th>
              </tr>
            </thead>
            {isSkeletonLoader === true ? (
              <tbody>
                <tr>
                  <td>{skeleton}</td>
                  <td>{skeleton}</td>
                  <td>{skeleton}</td>
                  <td>{skeleton}</td>
                  <td>{skeleton}</td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {data?.length > 0 &&
                  data?.map((item, index) => {
                    const adjustedIndex = index + (start - 1) * limit + 1;
                    return (
                      <tr>
                        <td>
                          <span>{adjustedIndex}</span>
                        </td>
                        <td>
                          <video
                            controls
                            src={item?.link}
                            height="80"
                            width="80"
                          />
                        </td>
                        <td>
                        <span>{item?.link}</span>
                        </td>
                        <td>
                          <Button
                            btnIcon={<DeleteRoundedIcon />}
                            onClick={() => handleDelete(item?._id)}
                            newClass={"deleteBtn"}
                          />
                        </td>
                        <td>
                          <span className="text-nowrap">
                            {dayjs(item?.createdAt)?.format("DD MMM YYYY")}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            )}
          </table>
        </div>
        <Pagination
          type={"server"}
          start={start}
          limit={limit}
          totalData={total}
          setStart={setStart}
          setLimit={setLimit}
          handleRowsPerPage={handleRowsPerPage}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};
export default VideoCallVideo;
