import logo from "./logo.svg";
import "./App.css";
import Dashboard from "./Component/Page/Dashboard";
import { Route, Routes, useNavigate } from "react-router-dom";
import Admin from "./Component/Page/Admin";
import Login from "./Component/Page/Login";
import "./assets/css/style.scss";
import "./assets/css/custom.scss";
import "./assets/css/default.scss";
import "./assets/css/responsive.scss";
import { useDispatch, useSelector } from "react-redux";
import { logout, setOldAdmin } from "./store/slice/authSlice";
import { setToken } from "./Component/utils/setAuth";
import { useEffect, useState } from "react";
import PrivateRoute from "./Component/utils/PrivateRoute";
import Error404 from "./Component/extra/Error404";
import { isLoading } from "./Component/utils/allSelector";
import Registration from "./Component/Page/Registration";
import axios from "axios";
import UpdateCode from "./Component/Page/UpdateCode";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const roleLoader = useSelector(isLoading);
  const key = sessionStorage.getItem("key");
  const token = sessionStorage.getItem("token");
  const [formattedDate, setFormattedDate] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  const [login, setLogin] = useState(false);

  useEffect(() => {
    axios
      .get("/admin/login")
      .then((res) => {
        setLogin(res.data.login);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    let timeoutId;
    if (token) {
      const resetTimeout = () => {
        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
          setIsLoggedIn(false);
        }, 100000);
      };

      resetTimeout();
      const events = ["mousedown", "keydown", "mousemove", "scroll"];
      events.forEach((event) => {
        document.addEventListener(event, resetTimeout);
      });
      return () => {
        clearTimeout(timeoutId);
        setIsLoggedIn(true);
        events.forEach((event) => {
          document.removeEventListener(event, resetTimeout);
        });
      };
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (!token && !key) return;
    dispatch(setOldAdmin(token));
  }, [setToken, key]);

  return (
    <div style={{ overflow: `${roleLoader === true ? "hidden" : ""}` }}>
      <Routes>
        <Route path={`${`/`}`} element={<Login />} />
        {/* {login === true && <Route path="/" element={<Login />} />}
        {login === false && <Route path="/" element={<Registration />} />}
        {login && <Route path="/login" element={<Login />} />}
        
        {login && <Route path="/code" element={<UpdateCode />} />} */}

        <Route element={<PrivateRoute />}>
          <Route path="/admin/*" element={<Admin />} />

          {/* <Route path="/admin/*" element={<Admin />} /> */}
        </Route>
        <Route path="*" element={<Error404 />} />
      </Routes>
    </div>
  );
}

export default App;
