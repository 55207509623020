import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slice/authSlice";
import allAppReducer from "./slice/appSlice";
import dialogReducer from "./slice/dialogueSlice";
import shortVideoSlice from "./slice/shortvideoSlice";
import liveStreamingVideoSlice from "./slice/liveStreamSlice";
import videoCallVideoSlice from "./slice/videoCallSlice";
import postSlice from "./slice/postSlice";
import notificationSlice from "./slice/notificationSlice";
import hostSlice from "./slice/hostSlice";
import GiftReducer from "./slice/GiftSlice";
import complainSlice from "./slice/complainSlice";
import userSlice from "./slice/userSlice";
import PurchasePlanSlice from "./slice/PurchasePlanSlice";

const store = configureStore({
  reducer: {
    admin: authReducer,
    app: allAppReducer,
    gift: GiftReducer,
    dialogue: dialogReducer,
    user: userSlice,
    purchasePlan: PurchasePlanSlice,
    complain: complainSlice,
    hostData: hostSlice,
    shortVideo: shortVideoSlice,
    liveStreamVideo: liveStreamingVideoSlice,
    videoCallVideo: videoCallVideoSlice,
    post: postSlice,
    notification: notificationSlice,
  },
});

export default store;
