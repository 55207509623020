import React, { useEffect, useState } from "react";
import Pagination from "../../extra/Pagination";
import Table from "../../extra/Table";
import ButtonCustom from "../../extra/Button";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import Searching from "../../extra/Searching";
import { useNavigate } from "react-router-dom";
import {
  getGiftCategory,
  deleteGiftCategory,
} from "../../../store/slice/GiftSlice";
import { connect, useDispatch, useSelector } from "react-redux";
import { openDialog } from "../../../store/slice/dialogueSlice";
import SVGA from "svgaplayerweb";
import { permissionError, warning } from "../../utils/Alert";
import dayjs from "dayjs";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import styled from "@emotion/styled";
import { Button, Menu, MenuItem, alpha } from "@mui/material";
import AddSvgaGiftDialog from "../../dialogComponent/AddSvgaGiftDialog";
import GiftImageDialog from "../../dialogComponent/GiftImageDialog";
import GiftCategoryDialog from "../../dialogComponent/GiftCategoryDialog";
import { isSkeleton } from "../../utils/allSelector";
import { baseURL } from "../../utils/config";

const SkeletonLoaderGift = () => (
  <div className="giftCategoryWiseLoader col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
    <div className="gift-Image">
      <div className="giftImageShow">
        <p></p>
      </div>
      <div className="giftText">
        <h5></h5>
        <h4></h4>
        <div className="gift-button">
          <ButtonCustom newClass={"edit-btn"} />
          <ButtonCustom newClass={"delete-btn"} />
        </div>
      </div>
    </div>
  </div>
);

function GiftCategory(props) {
  const { allGiftCategory, totalGiftCategory } = useSelector(
    (state) => state.gift
  );
  const isSkeletonLoader = useSelector(isSkeleton);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);
  
  const hasPermission = useSelector(
    (state) => state.admin.admin.flag
  );
  const [data, setData] = useState([]);
  const [start, setStart] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const payload = {
      start: start,
      limit: limit,
    };
    props.getGiftCategory(payload);
  }, [start, limit]);

  useEffect(() => {
    setData(allGiftCategory);
  }, [allGiftCategory]);

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handleDeleteGift = (item) => {
    if (!hasPermission) return permissionError();

    const data = warning();
    data
      .then((res) => {
        if (res) {
          if (res) {
            const payload = {
              id: item?._id,
              name: item?.name,
            };
            props.deleteGiftCategory(payload);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const handleNewOpenDialog = (type) => {
    if (!hasPermission) return permissionError();

    setAnchorEl(null);
    const payload = {
      dialogue: true,
      dialogueType: type,
      dialogueData: null,
    };
    dispatch(openDialog(payload));
  };

  useEffect(() => {
    data?.map((item) =>
      item?.gift?.map((gift, index) => {
        if (gift?.image?.split(".")?.pop() === "svga") {
          var player = new SVGA.Player(`div[attr="${index}"]`);
          var parser = new SVGA.Parser(`div[attr="${index}"]`);
          parser.load(gift.image, function (videoItem) {
            player.setVideoItem(videoItem);
            player.startAnimation();
          });
        }
      })
    );
  }, [data]);

  const handleEditGift = (item) => {
    if (!hasPermission) return permissionError();

    const payload = {
      dialogue: true,
      dialogueType: "giftCategory",
      dialogueData: item,
    };

    dispatch(openDialog(payload));
  };

  const giftCategoryData = [
    {
      Header: "Index",
      body: "index",
      Cell: ({ index }) => (
        <span className="  text-nowrap">
          {(start - 1) * limit + parseInt(index) + 1}
        </span>
      ),
    },

    {
      Header: "Category Image",
      body: "category image",
      Cell: ({ row }) => (
        <div className="image-showTable mx-auto">
         
         <span className="  text-nowrap">
         <img src={baseURL +  row?.image} height={50} width={50} />
        </span>
        
        </div>
      ),
    },

    {
      Header: "Gift count",
      body: "Gift count",
      Cell: ({ row }) => <span className="  text-nowrap">{row?.giftCount}</span>,
    },

    {
      Header: "Name",
      body: "name",
      Cell: ({ row }) => <span className="  text-nowrap">{row?.name}</span>,
    },
    {
      Header: "Action",
      body: "action",
      Cell: ({ row }) => (
        <div className="d-flex justify-content-center gap-3">
        <ButtonCustom
          btnIcon={<EditRoundedIcon />}
          newClass={"edit-btn"}
          onClick={() => handleEditGift(row)}
        />
        <ButtonCustom
        btnIcon={<DeleteRoundedIcon />}
        newClass={"delete-btn"}
        onClick={() => handleDeleteGift(row)}
      />
      </div>
      ),
    }
  ];

  return (
    <div className="game-table giftCategory">
      {dialogueType === "giftCategory" && <GiftCategoryDialog />}
      <h6 style={{ marginTop: "10px" }}>
        Total Gift Category :{" "}
        {allGiftCategory?.length ? allGiftCategory?.length : "0"}
      </h6>
      <div className="row mt-3 pt-1">
        <div className="col-8">
          <Searching
            placeHolder={"What’re you searching for?"}
            type="client"
            buttonShow={true}
            data={allGiftCategory}
            setData={setData}
            onFilterData={handleFilterData}
            searchValue={search}
          />
        </div>
        <div className="col-4 new-button d-flex justify-content-end">
          <ButtonCustom
            btnName={"New"}
            btnIcon={<AddRoundedIcon />}
            type={"button"}
            onClick={() => handleNewOpenDialog("giftCategory")}
            newClass={"new-btn"}
          />
        </div>
      </div>
      <Table
          data={data}
          type={"server"}
          mapData={giftCategoryData}
          start={start}
          limit={limit}
        />
    </div>
  );
}
export default connect(null, { getGiftCategory, deleteGiftCategory })(
  GiftCategory
);
