import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../store/slice/dialogueSlice";
import CancelIcon from "@mui/icons-material/Cancel";
import { IconButton } from "@mui/material";
import { vipPlanUpdate, addVipPlan } from "../../store/slice/appSlice";
import Input from "../extra/Input";
import Button from "../extra/Button";
import Textarea from "../extra/TextArea";
import { useLocation } from "react-router-dom";

const VipPlanDialogue = (props) => {
  const dispatch = useDispatch();
  const selectTimeData = [
    "Second",
    "Minute",
    "Hour",
    "Day",
    "Month",
    "Year",
    "Life Time",
  ];
  const { dialogueData } = useSelector((state) => state.dialogue);
  const [planName, setPlanName] = useState("");
  const [validity, setValidity] = useState("");
  const [validityType, setValidityType] = useState("");
  const [dollar, setDollar] = useState("");
  const [rupee, setRupee] = useState("");
  const [discount, setDiscount] = useState("");
  const [productKey, setProductKey] = useState("");
  const [note, setNote] = useState("");

  const location = useLocation();
  const [error, setError] = useState({
    planName: "",
    validity: "",
    validityType: "",
    rupee: "",
    dollar: "",
    discount: "",
    productKey: "",
    note: "",
    selectedDeveloper: "",
  });

  const handleClose = () => {
    dispatch(closeDialog());
  };

  useEffect(() => {
    if (dialogueData) {
      setPlanName(dialogueData?.name);
      setValidity(dialogueData?.validity);
      setValidityType(dialogueData?.validityType);
      setDollar(dialogueData?.dollar);
      setRupee(dialogueData?.rupee);
      setDiscount(dialogueData?.discount);
      setProductKey(dialogueData?.productKey);
      setNote(dialogueData?.note);
    }
  }, [dialogueData]);

  const handleSubmit = (e) => {
    if (
      !planName ||
      !validity ||
      !validityType ||
      !dollar ||
      !rupee ||
      !discount ||
      !productKey
    ) {
      let error = {};
      if (!planName) error.planName = "Name Is Required !";
      if (!validity) error.validity = "Validity  Is Required !";
      if (!validityType) error.validityType = "Validity Type  Is Required !";
      if (!dollar) error.dollar = "Dollar Is Required !";
      if (!rupee) error.rupee = "Rupee Is Required !";
      if (!discount) error.discount = "Discount Is Required !";
      if (!productKey) error.productKey = "Product Key Is Required !";
      return setError({ ...error });
    } else {
      const formData = new FormData();
      formData.append("name", planName);
      formData.append("validity", validity);
      formData.append("validityType", validityType);
      formData.append("dollar", dollar);
      formData.append("rupee", rupee);
      formData.append("discount", discount);
      formData.append("productKey", productKey);
      if (note) {
        formData.append("note", note);
      }

      if (dialogueData) {
        formData.append("planId", dialogueData?._id);
        const payload = {
          appId: location?.state?.editAppData?._id,
          planId: dialogueData?._id,
          data: formData,
        };
        dispatch(vipPlanUpdate(payload));
        dispatch(closeDialog());
      } else {
        const payload = {
          appId: location?.state?.editAppData?._id,
          data: formData,
        };
        dispatch(addVipPlan(payload));
        dispatch(closeDialog());
      }
    }
  };

  return (
    <div className="vipPlan-edit">
      <div className="dialog">
        <div className="mainDiaogBox">
          <div className="dialog-head">
            <h4>{dialogueData ? "Update VipPlan" : "Create VipPlan"}</h4>
            <IconButton onClick={handleClose}>
              <CancelIcon />
            </IconButton>
          </div>
          <form>
            <div className="dialog-body">
              <div className="row">
                <div className="col-12 col-md-6">
                  <Input
                    label={`Name`}
                    id={`planName`}
                    type={`text`}
                    value={planName}
                    errorMessage={error.planName && error.planName}
                    onChange={(e) => {
                      setPlanName(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          planName: `Name Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          planName: "",
                        });
                      }
                    }}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={`Validity`}
                    id={`validity`}
                    type={`number`}
                    value={validity}
                    errorMessage={error.validity && error.validity}
                    onChange={(e) => {
                      setValidity(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          validity: `Validity Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          validity: "",
                        });
                      }
                    }}
                  />
                </div>
                <div className="col-12 col-md-6 select-time-selector">
                  <h6>Select Validity Type</h6>
                  <select
                    value={validityType}
                    name="selectValidityType"
                    onChange={(e) => {
                      setValidityType(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          validityType: `Validity Type Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          validityType: "",
                        });
                      }
                    }}
                  >
                    {selectTimeData?.map((item) => {
                      return <option value={item}>{item}</option>;
                    })}
                  </select>
                  {error?.validityType && (
                    <p className="errorMessage">{error?.validityType}</p>
                  )}
                </div>

                <div className="col-12 col-md-6">
                  <Input
                    label={`Dollar`}
                    id={`dollar`}
                    type={`number`}
                    value={dollar}
                    errorMessage={error.dollar && error.dollar}
                    onChange={(e) => {
                      setDollar(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          dollar: `Dollar Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          dollar: "",
                        });
                      }
                    }}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={`Rupee`}
                    id={`rupee`}
                    type={`number`}
                    value={rupee}
                    errorMessage={error.rupee && error.rupee}
                    onChange={(e) => {
                      setRupee(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          rupee: `Rupee Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          rupee: "",
                        });
                      }
                    }}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={`Discount`}
                    id={`discount`}
                    type={`number`}
                    value={discount}
                    errorMessage={error.discount && error.discount}
                    onChange={(e) => {
                      setDiscount(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          discount: `Discount Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          discount: "",
                        });
                      }
                    }}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={`Product Key`}
                    id={`productKey`}
                    type={`text`}
                    value={productKey}
                    errorMessage={error.productKey && error.productKey}
                    onChange={(e) => {
                      setProductKey(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          productKey: `Product Key Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          productKey: "",
                        });
                      }
                    }}
                  />
                </div>
                <div className="col-12 mt-2">
                  <Textarea
                    label={`Note`}
                    placeholder={"Enter Note..."}
                    onChange={(e) => {
                      setNote(e.target.value);
                    }}
                    row={2}
                    value={note}
                    name={"note"}
                  />
                </div>
              </div>
            </div>
            <div className="row dialog-footer">
              <div className="col-6"></div>
              <div className="col-6 mt-2 d-flex align-items-center justify-content-end">
                <Button
                  btnName={"Cancel"}
                  onClick={handleClose}
                  newClass={"cancel-btn"}
                />
                <Button
                  btnName={"Submit"}
                  type={"button"}
                  style={{ marginLeft: "10px" }}
                  onClick={handleSubmit}
                  newClass={"submit-btn"}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default connect(null, { vipPlanUpdate, addVipPlan })(VipPlanDialogue);
