import { IconButton, Rating } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import React, { Fragment, useEffect, useState } from "react";
import SVGA from "svgaplayerweb";
import Videogames from "../../assets/images/icon/GameDefaultImage.png";
import {
  createGift,
  editGift,
  getGiftCategory,
} from "../../store/slice/GiftSlice";
import { closeDialog } from "../../store/slice/dialogueSlice";
import { connect, useDispatch, useSelector } from "react-redux";
import Input from "../extra/Input";
import Button from "../extra/Button";
import { baseURL } from "../utils/config";
import ReactSelect from "react-select";

const AddSvgaGiftDialogue = (props) => {
  const dispatch = useDispatch();
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );
  const { allGiftCategory } = useSelector((state) => state.gift);

  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [images, setImages] = useState([]);
  const [imageData, setImageData] = useState(null);
  const [imagePath, setImagePath] = useState(null);
  const [rating, setRating] = useState("");
  const [coin, setCoin] = useState("");
  const [selectCategoryId, setSelectCategoryId] = useState("");
  const [categoryOption, setCategoryOption] = useState("");
  const [selectedOptions, setSelectedOptions] = useState();
  const [isSvga, setIsSvga] = useState(false);
  const [error, setError] = useState({
    coin: "",
    image: "",
    category: "",
  });

  const handleClose = () => {
    dispatch(closeDialog());
  };

  useEffect(() => {
    props.getGiftCategory();
  }, []);

  useEffect(() => {
    const allGiftCategoryFilter = allGiftCategory?.filter(
      (item) => item?._id === dialogueData?.giftData?.giftCategoryId
    );
    if (dialogueData) {
      setCoin(dialogueData?.giftData?.coin);
      setSelectCategoryId(allGiftCategoryFilter[0]?._id);
      setSelectedOptions(allGiftCategoryFilter[0]);
      setImagePath(dialogueData?.giftData?.image);
      if (dialogueData?.giftData?.image?.split(".")?.pop() === "svga") {
        setIsSvga(true);
      }
    }
  }, [dialogueData, allGiftCategory]);

  useEffect(() => {
    setCategoryOption(allGiftCategory);
  }, [allGiftCategory]);

  const CustomOption = ({ innerProps, label, data }) => (
    <div {...innerProps} className="moreApp-optionList">
      <img src={baseURL +  data?.image} />
      <span>{data?.name}</span>
    </div>
  );

  const handleSelectChange = (selected, type) => {
    setSelectedOptions(selected);
    setSelectCategoryId(selected?._id);

    if (!selected) {
      return setError({
        ...error,
        category: `Category Is Required`,
      });
    } else {
      return setError({
        ...error,
        category: "",
      });
    }
  };

  const handleFileUpload = (e) => {
    if (e.target.files[0]) {
      Object.assign(e.target.files[0], {
        preview: URL.createObjectURL(e.target.files[0]),
      });
      setImageData(e.target.files[0]);
      setImages([e.target.files[0]]);
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        setImagePath(reader.result);
        setError({
          image: "",
        });
      });
      reader.readAsDataURL(e.target.files[0]);

      if (e.target.files[0].name.split(".").pop() === "svga") {
        setIsSvga(true);
      } else {
        setIsSvga(false);
      }
    }
    if (!e.target.files[0]) {
      return setError({
        ...error,
        image: `Image Is Required`,
      });
    } else {
      return setError({
        ...error,
        image: "",
      });
    }
  };

  const handleSubmit = (e) => {
    const validImage = dialogueData?.giftData ? !imageData : images?.length > 0;
    if (!coin || !selectCategoryId || !validImage) {
      let error = {};
      if (!coin) error.coin = "Coin Is Required !";
      if (!selectCategoryId) error.category = "Category Is Required !";
      if (!validImage) {
        error.image = "Image Is Required !";
      }

      return setError({ ...error });
    } else {
      const formData = new FormData();
      if (dialogueData?.giftData) {
        formData.append("image", imageData);
      } else {
        for (let i = 0; i < images.length; i++) {
          formData.append("image", images[i]);
        }
      }
      formData.append("coin", coin);
      formData.append("type", 3);
      formData.append("giftCategoryId", selectCategoryId);
      if (dialogueData?.giftData) {
        const payload = {
          id: dialogueData?.giftData?._id,
          data: formData,
          categoryId:selectCategoryId
        };
        props.editGift(payload);
        dispatch(closeDialog());
      } else {
        const payload = {
          data: formData,
          categoryId:selectCategoryId
        };
        props.createGift(payload);
        dispatch(closeDialog());
      }
    }
  };

  useEffect(() => {
    if (isSvga) {
      if (!!document.getElementById("svga") && imagePath) {
        var player = new SVGA.Player(
          `div[attr="${dialogueData?._id ? dialogueData?._id : "1"}"]`
        );
        var parser = new SVGA.Parser(
          `div[attr="${dialogueData?._id ? dialogueData?._id : "1"}"]`
        );
        if (imageData?.preview) {
          parser.load(imageData.preview, function (videoItem) {
            player.setVideoItem(videoItem);
            player.startAnimation();
          });
        } else {
          parser.load(dialogueData?.giftData?.image, function (videoItem) {
            player.setVideoItem(videoItem);
            player.startAnimation();
          });
        }
      }
    }
  }, [imageData, isSvga, imagePath, dialogueData]);

  return (
    <div className="giftDialog">
      <div className="dialog">
        <div className="mainDiaogBox">
          <div className="dialog-head">
            <h4>{dialogueData ? "Update SVGA Gift" : "Add SVGA Gift"}</h4>
            <IconButton onClick={handleClose}>
              <CancelIcon />
            </IconButton>
          </div>
          <form>
            <div className="dialog-body">
              <div className="row">
                <div className="col-12">
                  <Input
                    label={`Coin`}
                    id={`coin`}
                    type={`number`}
                    value={coin}
                    errorMessage={error.coin && error.coin}
                    onChange={(e) => {
                      setCoin(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          coin: `Coin Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          coin: "",
                        });
                      }
                    }}
                  />
                </div>
                <div className="col-12 categorySelect">
                  <label className="label-text mb-0">Category</label>
                  <ReactSelect
                    options={categoryOption || []}
                    value={selectedOptions}
                    isClearable={false}
                    onChange={(e) => handleSelectChange(e, "addAds")}
                    getOptionValue={(option) => option._id}
                    formatOptionLabel={(option) => (
                      <div className="moreApp-option">
                        <img src={baseURL +  option?.image} />
                        <span>{option?.name}</span>
                      </div>
                    )}
                    components={{
                      Option: CustomOption,
                    }}
                  />
                  {error?.category && (
                    <p className="errorMessage">{error?.category}</p>
                  )}
                </div>
                <div className="col-12 image-input">
                  <label className="label-input">SVGA Image</label>
                  <input
                    type="file"
                    name="image"
                    id="image"
                    accept=".svga"
                    className="form-input"
                    onChange={handleFileUpload}
                  />
                  {error?.image && (
                    <p className="errorMessage">{error?.image}</p>
                  )}
                  {imagePath && (
                    <Fragment>
                      {!isSvga ? (
                        <img
                          src={imagePath}
                          class="mt-3 rounded float-left mb-2"
                          height="100px"
                          width="100px"
                        />
                      ) : (
                        <div
                          id="svga"
                          attr={dialogueData?._id ? dialogueData?._id : "1"}
                          style={{
                            boxShadow: "0 5px 15px 0 rgb(105 103 103 / 00%)",
                            marginTop: 10,
                            float: "left",
                            objectFit: "contain",
                            marginRight: 15,
                            height: "150px",
                            width: "150px",
                          }}
                        ></div>
                      )}
                    </Fragment>
                  )}
                </div>
              </div>
              <div className="row dialog-footer mt-2">
                <div className="col-6"></div>
                <div className="col-6 mt-2 d-flex align-items-center justify-content-end">
                  <Button
                    btnName={"Cancel"}
                    onClick={handleClose}
                    style={{ marginRight: "10px" }}
                    newClass={"cancel-btn"}
                  />
                  <Button
                    btnName={"Submit"}
                    type={"button"}
                    onClick={handleSubmit}
                    newClass={"submit-btn"}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { createGift, editGift, getGiftCategory })(
  AddSvgaGiftDialogue
);
