import React, { useEffect, useState } from "react";
import Input from "../../extra/Input";
import Button from "../../extra/Button";
import { useDispatch, useSelector } from "react-redux";
import { getAppDetails, updateSetting } from "../../../store/slice/appSlice";
import { permissionError } from "../../utils/Alert";

const Setting = () => {
  const dispatch = useDispatch();
  const { appDetails } = useSelector((state) => state.app);
  const selectCurrencyData = ["₹", "$"];

  const [privacyPolicyURL, setPrivacyPolicyURL] = useState("");
  const [oneSignalId, setOneSignalId] = useState("");
  const [onesignalApiKey, setOneSignalApiKey] = useState("");
  const [currency, setcurrency] = useState("All");
  const hasPermission = useSelector(
    (state) => state.admin.admin.flag
  );
  const [error, setError] = useState({
    privacyPolicyURL: "",
    oneSignalId: "",
    onesignalApiKey: "",
    currency: "",
  });

  useEffect(() => {
    dispatch(getAppDetails());
  }, [dispatch]);

  useEffect(() => {
    if (appDetails) {
      setPrivacyPolicyURL(appDetails?.privacyPolicyURL || "");
      setOneSignalId(appDetails?.oneSignalId || "");
      setOneSignalApiKey(appDetails?.onesignalApiKey || "");
      setcurrency(appDetails?.currencyMark);
    }
  }, [appDetails]);

  const handleSubmit = () => {
    if (!hasPermission) return permissionError();

    if (!privacyPolicyURL || !oneSignalId || !onesignalApiKey) {
      let error = {};
      if (!privacyPolicyURL)
        error.privacyPolicyURL = "Privacy Policy Url is required !";
      if (!oneSignalId) error.oneSignalId = "One signal id Is required !";
      if (!onesignalApiKey)
        error.onesignalApiKey = "One signal api key Is required !";

      return setError({ ...error });
    } else {
      const settingData = {
        privacyPolicyURL,
        oneSignalId,
        onesignalApiKey,
        currencyMark: currency,
      };

      const payload = {
        settingData,
        settingId: appDetails?._id,
      };

      dispatch(updateSetting(payload));
    }
  };

  return (
    <>
      <div className="d-flex justify-content-end mt-2">
        <Button
          btnName={"Submit"}
          type={"submit"}
          newClass={"submit-btn"}
          onClick={handleSubmit}
        />
      </div>

      <div className="d-flex flex-wrap justify-content-between">
        {" "}
        <div className="custom-column mt-3 setting-box">
          <h6 className="fw-bold">One Signal Setting</h6>
          <div className="interstitial-ad-box">
            <Input
              label={`One Signal Id`}
              id={`oneSignalId`}
              errorMessage={error.oneSignalId && error.oneSignalId}
              type={`text`}
              value={oneSignalId}
              onChange={(e) => {
                setOneSignalId(e.target.value);
                if (!e.target.value) {
                  setError({
                    ...error,
                    oneSignalId: "One signal id is required",
                  });
                } else {
                  setError({
                    ...error,
                    oneSignalId: "",
                  });
                }
              }}
            />
            <Input
              label={`One Signal Api Key`}
              id={`onesignalApiKey`}
              type={`text`}
              errorMessage={error.onesignalApiKey && error.onesignalApiKey}
              value={onesignalApiKey}
              onChange={(e) => {
                setOneSignalApiKey(e.target.value);
                if (!e.target.value) {
                  setError({
                    ...error,
                    onesignalApiKey: "One signal Api Key is required",
                  });
                } else {
                  setError({
                    ...error,
                    onesignalApiKey: "",
                  });
                }
              }}
            />
          </div>
        </div>
        {/* Flexbox container */}
        <div className="custom-column mt-3 setting-box height">
          <h6 className="fw-bold">Privacy Policy Setting</h6>
          <Input
            label={`Privacy Policy URL`}
            id={`privacyPolicyURL`}
            errorMessage={error.privacyPolicyURL && error.privacyPolicyURL}
            type={`text`}
            value={privacyPolicyURL}
            onChange={(e) => {
              setPrivacyPolicyURL(e.target.value);
              if (!e.target.value) {
                setError({
                  ...error,
                  privacyPolicyURL: "Privacy Policy Url is required",
                });
              } else {
                setError({
                  ...error,
                  privacyPolicyURL: "",
                });
              }
            }}
          />

         
        </div>

        <div className="custom-column mt-3 height">
        <div className="setting-box select-time-selector">
            <h6 className="fw-bold text-dark">Currency Setting</h6>
            <h6>Select Currency</h6>
            <select
              value={currency}
              name="selectcurrency"
              onChange={(e) => {
                setcurrency(e.target.value);
                if (!e.target.value) {
                  return setError({
                    ...error,
                    currency: `Currency Is Required`,
                  });
                } else {
                  return setError({
                    ...error,
                    currency: "",
                  });
                }
              }}
            >
              {selectCurrencyData?.map((item) => {
                return <option value={item}>{item}</option>;
              })}
            </select>
            {error?.currency && (
              <p className="errorMessage">{error?.currency}</p>
            )}
          </div>
          </div>
      </div>
    </>
  );
};

export default Setting;
